/*
Global
 */

export enum TileType {
  // Children
  'file' = 'file',
  'exercise' = 'exercise',
  'revision' = 'revision',
  'video' = 'video',
  'game' = 'game',
  // About
  'about' = 'about',
  // Parents
  'chronicle' = 'chronicle',
  'article' = 'article',
  'directory' = 'directory',
  'video-parent' = 'video-parent',
}

export enum TileSubtype {
  'revision' = 'revision'
}

export enum TileVariant {
  'download' = 'download',
  'play' = 'play'
}

export enum TileFormat {
  'xsmall' = 'xsmall',
  'small' = 'small',
  'medium' = 'medium',
  'large' = 'large'
}

export enum TileContext {
  'slider' = 'slider'
}

export enum ModuleType {
  // Children
  'blocSpecial' = 'blocSpecial',
  'media' = 'media',
  'listSteps' = 'listSteps',
  'multiColumn' = 'multiColumn',
  'tableOfContents' = 'tableOfContents',
  'text' = 'text',
  'youtube' = 'youtube',
  'exerciseList' = 'exerciseList',
  'redirects' = 'redirects',
  'vocabulary' = 'vocabulary',
  // About
  'aboutChildren' = 'aboutChildren',
  // Parents
  'quote' = 'quote',
  'blocSpecialParent' = 'blocSpecialParent'
}

export enum ModuleSubtype {
  // Children
  'ensavoirplus' = 'ensavoirplus',
  'regle' = 'regle',
  'formule' = 'formule',
  'exemple' = 'exemple',
  'truc' = 'truc',
  'important' = 'important',
  // Parents
  'tips_and_tools' = 'tips_and_tools',
  'attention' = 'attention',
  'definition' = 'definition',
  'example' = 'example',
  'did_you_know' = 'did_you_know'
}

export enum ColumnFormat {
  '50_50' = '50_50',
  '33_66' = '33_66',
  '66_33' = '66_33',
  '33_33_33' = '33_33_33'
}

/*
Children
 */

export enum FileType {
  file = 'file',
  video = 'video',
  game = 'game',
  exercise = 'exercise',
  revision = 'revision'
}

export enum FileTypeVal {
  files = 'file',
  videos = 'video',
  games = 'game',
  exercises = 'exercise',
  revisions = 'revision'
}

export enum RootFileCodes {
  math = 'm0000',
  french = 'f0000',
  chemistry = 'c0000',
  science = 's0000',
  history = 'h0000',
  physics = 'p0000',
  geography = 'g0000',
  english = 'a0000',
  revisions = 'r0000'
}

export enum Topic {
  math = 'math',
  french = 'french',
  chemistry = 'chemistry',
  science = 'science',
  history = 'history',
  physics = 'physics',
  geography = 'geography',
  english = 'english',
  native_communities = 'native_communities',
  others = 'others'
}

export enum TopicVal {
  math = 'mathematique',
  french = 'francais',
  chemistry = 'chimie',
  science = 'science',
  history = 'histoire',
  physics = 'physique',
  geography = 'geographie',
  english = 'anglais',
  native_communities = 'communautes_autochtones',
  others = 'autres'
}

/*
Parents
 */

export enum Thematic {
  'professional_orientation' = 'professional_orientation',
  'academic_progress' = 'academic_progress',
  'child_development' = 'child_development',
  'family_daily' = 'family_daily',
  'homework' = 'homework',
  'learning_disabilities' = 'learning_disabilities',
}

export enum ScholarLevelParent {
  'first_primary_cycle' = 'first_primary_cycle',
  'second_primary_cycle' = 'second_primary_cycle',
  'third_primary_cycle' = 'third_primary_cycle',
  'first_secondary_cycle' = 'first_secondary_cycle',
  'second_secondary_cycle' = 'second_secondary_cycle',
}

export enum TipToolSubtype {
  'tip' = 'tip',
  'tool' = 'tool'
}

/*
Others
 */

export enum NoSpacingBreakpoint {
  // See: _base-section.scss for related styling, create it if non-existent
  'all' = 'all',
  'min_small' = 'min_small',
  'min_smedium' = 'min_smedium',
  'min_medium' = 'min_medium',
  'min_large' = 'min_large',
  'min_large1' = 'min_large1',
  'min_large2' = 'min_large2',
  'min_large3' = 'min_large3',
  'min_xlarge' = 'min_xlarge',
  'min_small+1' = 'min_small+1',
  'min_smedium+1' = 'min_smedium+1',
  'min_medium+1' = 'min_medium+1',
  'min_large+1' = 'min_large+1',
  'min_large1+1' = 'min_large1+1',
  'min_large2+1' = 'min_large2+1',
  'min_large3+1' = 'min_large3+1',
  'min_xlarge+1' = 'min_xlarge+1',
  'max_small-1' = 'max_small-1',
  'max_smedium-1' = 'max_smedium-1',
  'max_medium-1' = 'max_medium-1',
  'max_large-1' = 'max_large-1',
  'max_large1-1' = 'max_large1-1',
  'max_large2-1' = 'max_large2-1',
  'max_large3-1' = 'max_large3-1',
  'max_xlarge-1' = 'max_xlarge-1',
  'max_small' = 'max_small',
  'max_smedium' = 'max_smedium',
  'max_medium' = 'max_medium',
  'max_large' = 'max_large',
  'max_large1' = 'max_large1',
  'max_large2' = 'max_large2',
  'max_large3' = 'max_large3',
  'max_xlarge' = 'max_xlarge',
}

export enum Breakpoints {
  small = 480,
  smedium = 600,
  medium = 940,
  large = 1024,
  large1 = 1280,
  large2 = 1440,
  large3 = 1600,
  xlarge = 1920
}
