import { Injectable } from '@angular/core';
// import { FormControl } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { UserService } from '../user/user.service';
import { User, UserLogin } from '@src/app/models/models';
import { ApiService } from '@app/services/api/api.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public user: User = null;

  constructor(
    private afAuth: AngularFireAuth,
    private userService: UserService,
    private apiService: ApiService) {
    }

  register(user: User) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.auth.createUserWithEmailAndPassword(user.email, user.password)
      .then(res => {
        return this.afAuth.auth.currentUser.sendEmailVerification().then(() => {
          user.id = res.user.uid;
          delete user.email_verified;
          this.userService.createUser(user);
          resolve(res);
         });
      },
      err => reject(err));
    });
  }

  updateCurrentUserPassword(password: string) {
    return this.afAuth.auth.currentUser.updatePassword(password);
  }

  // emailAsyncValidator() {
  //   return (input: FormControl) => {
  //     return this.checkEmail(input.value)
  //       .then(res => { return res ? { emailExists: true } : null });
  //   };
  // }

  // checkEmail(email: string): Promise<boolean> {
  //   return new Promise<boolean>((resolve, reject) => {
  //     this.afAuth.auth.fetchSignInMethodsForEmail(email)
  //       .then(() => resolve(true), () => reject(false));
  //   });
  // }

  login(userLogin: UserLogin) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.auth.signInWithEmailAndPassword(userLogin.email, userLogin.password)
      .then(res => {
        // Create a session cookie for other app on domain.
        this.ssoLogin(res.user);
        resolve(res);
      },
      err => reject(err));
    });
  }

  forgotPassword(email: string): Promise<void> {
    const siteUrl = window.location.href.split('/').slice(0, 3).join('/');
    const actionCodeSettings = {
      url: siteUrl + '/profil',
      handleCodeInApp: false
    };
    return this.afAuth.auth.sendPasswordResetEmail(email, actionCodeSettings);
  }

  ssoLogin(user) {
    return user.getIdTokenResult().then(result => {
      const body = { idToken: result.token };
      this.apiService.post(environment.ssoSigninUrl, body).subscribe(() => {
        //console.log('sso login');
      },
      error => {
          console.log(error);
        }
      );
    });
  }

  logout() {
    return this.afAuth.auth.signOut();
  }

  // When the state of firebase authentication changed, ie user logs in or logs out, a user or null
  // is sent to all subscribers
  getCurrentUser(): Observable<User> {
    return new Observable<User>(observer => {
      const firebaseUnsub = this.afAuth.auth.onAuthStateChanged((user) => {
        if (user) {
          this.userService.getUser(user.uid)
            .then((dbUser) => {
              observer.next(dbUser);
            })
            .catch((err) => {
              observer.next(null);
            });
        } else {
          observer.next(null);
        }
      });
      return {
        unsubscribe() {
          // When unsubscribing from getCurrentUser, we must also unsubscribe from the onAuthStateChanged
          firebaseUnsub();
        }
      };
    });
  }
}
