<div class="hub-redirect {{classModifier ? 'hub-redirect--'+classModifier : 'default' }} ">
  <div class="hub-redirect__btn-inner-wrapper" [attr.data-is-overflow]="isOverflow">
    <div  *ngFor="let item of listItems" >
      <app-button
        [isThemed]="isThemed"
        [theme]="isThemed ? itemTheme(item) : ''"
        [test]="item.test"
        [routerLink]="item.link"
        [queryParams] = "{ keywords: item.keywords}"
        [label]="item.label"
        [classModifier] = "'list-margins btn--home-big'"
      >
      </app-button>
    </div>
  </div>
</div>

