import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SelectivePreloadingStrategy } from './core/selective-preloading-strategy';
import { UnauthorizedComponent } from './modules/error/unauthorized/unauthorized.component';
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import 'firebase/auth';
import { environment } from '@env/environment';

const redirectUnauthorizedToLogin = () => {
  return location.pathname.startsWith('/en')
    ? redirectUnauthorizedTo([ '/en/auth/login' ])
    : redirectUnauthorizedTo([ '/auth/connexion' ]);
};

let routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then(mod => mod.HomeModule),
    data: { animation: 'Home', preload: false }
  },
  {
    path: 'bv',
    loadChildren: () => import('./modules/library/library.module').then(mod => mod.LibraryModule),
    data: { animation: 'Library', preload: true }
  },
  {
    path: 'non-authorise',
    component: UnauthorizedComponent
  }
];

// Todo: test only there in case of going live in urgency
if (!environment.production) {
  routes = routes.concat([
    {
      path: 'auth',
      loadChildren: () => import('./modules/auth/auth.module').then(mod => mod.AuthModule),
      data: { animation: 'Auth', preload: false }
    },
    {
      path: 'profil',
      loadChildren: () => import('./modules/account/account.module').then(mod => mod.AccountModule),
      canActivate: [ AngularFireAuthGuard ],
      data: { animation: 'Account', preload: false, authGuardPipe: redirectUnauthorizedToLogin }
    },
    {
      path: 'parents',
      loadChildren: () => import('./modules/parents/parents.module').then(mod => mod.ParentsModule),
      data: { animation: 'Parents', preload: false }
    },
    {
      path: 'a-propos',
      loadChildren: () => import('./modules/about/about.module').then(mod => mod.AboutModule),
      data: { animation: 'About', preload: false }
    },
    {
      path: 'equipe',
      loadChildren: () => import('./modules/team/team.module').then(mod => mod.TeamModule),
      data: { animation: 'Team', preload: false }
    },
    {
      path: 'contact',
      loadChildren: () => import('./modules/contact/contact.module').then(mod => mod.ContactModule),
      data: { animation: 'Contact', preload: false }
    },
    {
      path: 'blogue',
      loadChildren: () => import('./modules/blog/blog.module').then(mod => mod.BlogModule),
      data: { animation: 'Blog', preload: false }
    },
    {
      path: 'concours',
      loadChildren: () => import('./modules/contests/contests.module').then(mod => mod.ContestsModule),
      data: { animation: 'Contest', preload: false }
    },
    {
      path: 'partenaires',
      loadChildren: () => import('./modules/partners/partners.module').then(mod => mod.PartnersModule),
      data: { animation: 'Partners', preload: false }
    },
    {
      path: 'medias',
      loadChildren: () => import('./modules/media/media.module').then(mod => mod.MediaModule),
      data: { animation: 'Medias', preload: false }
    },
    {
      path: 'legal',
      loadChildren: () => import('./modules/legal/legal.module').then(mod => mod.LegalModule),
      data: { animation: 'Legal', preload: false }
    },
    // English
    {
      path: 'en/parents',
      loadChildren: () => import('./modules/parents/parents.module').then(mod => mod.ParentsModule),
      data: { animation: 'Parents', preload: false }
    },
    // Style guide
    {
      path: 'style-guide',
      loadChildren: () => import('./modules/style-guide/style-guide.module').then(mod => mod.StyleGuideModule),
      data: { animation: 'StyleGuide', preload: false }
    }
  ]);
}

// Inject errors at the end
routes = routes.concat([
  {
    path: '404',
    loadChildren: () => import('./modules/error/not-found/not-found.module').then(mod => mod.NotFoundModule),
    data: { preload: false }
  },
  {
    path: '**',
    redirectTo: '/404'
  }
]);

export {
  routes
};

@NgModule({
  declarations: [ UnauthorizedComponent ],
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: SelectivePreloadingStrategy,
      scrollPositionRestoration: 'enabled'
    })
  ],
  providers: [ SelectivePreloadingStrategy ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {
}
