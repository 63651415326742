const getGameFullGQL = `
{
  game(lang: "code_langue", slug: "code_slug") {
    uuid
    title
    slug
    lang
    otherLangSlug
    body
    linkAppleStore
    linkGooglePlay
    externalLink
    topic
    levels
    tags
    metatags {
      tag
      attributes {
        property
        name
        rel
        href
        content
      }
    }
    image {
      url
      alt
    }
    imageStyles {
      style
      link
    }
    vignette {
      url
      alt
    }
    vignetteStyles {
      style
      link
    }
    content {
      type
      ... on media {
        type
        source
        image {
          url
          alt
        }
        imageStyles {
          style
          link
        }
        legend
        title
        hasDecoration
        description
      }
      ... on text {
        type
        title
        slug
        text
      }
      ... on youtubeBlog {
        type
        video {
          videoId
          image {
            url
            alt
          }
          imageStyles {
            style
            link
          }
        }
      }
    }
  }
}
`;

  export {
    getGameFullGQL
  };
