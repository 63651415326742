import { Component, Input } from '@angular/core';
import { Limbs } from '@app/shared/components/flo/flo.types';

@Component({
  selector: 'app-flo-limbs',
  templateUrl: './flo-limbs.component.html',
  styleUrls: ['./flo-limbs.component.scss']
})
export class FloLimbsComponent {
  @Input() limbs: Limbs;
  @Input() minimized: boolean;
  @Input() asleep: boolean;

  constructor() {}
}
