export class TableOverflow {

  elem: HTMLElement;
  wrapperScroll: HTMLElement;
  id: string;
  overflow: string;

  constructor(elem, id) {
    this.elem = elem;

    this.id = id;
    this.overflow = 'right';
    const orgHtml = this.elem.innerHTML;
    const newHtml = `<div id=${this.id} class='wrapper-scroll-table'>` + orgHtml + '</div>';
    this.elem.innerHTML = newHtml;

    setTimeout(() => {
      this.wrapperScroll = elem.querySelector('.wrapper-scroll-table');
      this.scrollCalculation();
      this.wrapperScroll.addEventListener('scroll', this.scrollCalculation.bind(this));
    }, 200);
  }

  scrollCalculation() {
    // check for resize
    const parentSize = this.wrapperScroll.offsetWidth;
    const scrollWidth = this.wrapperScroll.scrollWidth - parentSize ;

    if (this.wrapperScroll.scrollLeft <= 0) {
      this.overflow = 'right';
    } else if (this.wrapperScroll.scrollLeft > 0 && this.wrapperScroll.scrollLeft < (scrollWidth - 10)) {
      this.overflow = 'center';
    } else {
      this.overflow = 'left';
    }

    this.elem.setAttribute('data-overflow', this.overflow);
  }

  clearTable() {
    this.wrapperScroll.removeEventListener('scroll', this.scrollCalculation);
    this.id = null;
    this.elem = null;
    this.wrapperScroll = null;
    this.overflow = null;
  }
}
