<div class="btn-wrapper {{classModifier ? 'btn-wrapper--'+classModifier : '' }}">
  <a
    class="btn btn--md"
    [class.btn--themed]="isThemed"
    [attr.data-theme]="theme"
    [attr.data-test]="test"
    [routerLink]="link"
  >
    {{label}}
  </a>
</div>

