const getFileFullGQL = `
{
  file(lang: "$lang", number: "$code") {
    uuid
    title
    type
    subtype
    introduction
    lang
    code
    parentCode
    parentUuid
    published
    slug
    otherLangSlug
    metatags {
      tag
      attributes {
        property
        name
        rel
        href
        content
      }
    }
    navigation {
      text
      anchor
    }
    children {
      title
      slug
      code
      topic
    }
    image {
      url
      alt
    }
    imageStyles {
      style
      link
    }
    tags
    topic
    levels
    references {
      texts {
        text
      }
      links {
        typeLink
        text
        url
      }
    }
    sections {
      type
      ... on moduleContent {
        type
        title
        slug
        modules {
          type
          ... on exerciseList {
            type
            uuid
            title
            slug
            totalColumns
            isSlider
            content {
              type
              ... on exercise {
                type
                uuid
                finalTitle
                finalSlug
                titleParagraph
                slugParagraph
                exercise {
                  uuid
                  title
                  code
                  url
                  tags
                }
              }
            }
            exercises {
              type
              title
              slug
              code
              url
            }
          }
          ... on listSound {
            type
            sounds {
              type
              ... on sound {
                name
                sound
                image {
                  url
                  alt
                }
                imageStyles {
                  style
                  link
                }
              }
            }
          }
          ... on text {
            type
            title
            slug
            text
          }
          ... on media {
            type
            title
            hasDecoration
            description
            source
            legend
            image {
              url
              alt
            }
            imageStyles {
              style
              link
            }
          }
          ... on youtube {
            type
            uuid
            finalTitle
            finalSlug
            titleParagraph
            slugParagraph
            video {
              uuid
              title
              code
              image {
                url
                alt
              }
              imageStyles {
                style
                link
              }
              description
              videoId
              tags
            }
          }
          ... on tableOfContents {
            type
            title
            text
            links {
              type
              ... on linkTdm {
                type
                typeLink
                text
                url
                level
              }
            }
          }
          ... on blocSpecial {
            type
            subtype
            text
            surtitle
            title
            slug
            submodules {
              type
              ... on exerciseList {
                type
                uuid
                title
                slug
                totalColumns
                isSlider
                content {
                  type
                  ... on exercise {
                    type
                    uuid
                    finalTitle
                    finalSlug
                    titleParagraph
                    slugParagraph
                    exercise {
                      uuid
                      title
                      code
                      url
                      tags
                    }
                  }
                }
                exercises {
                  type
                  title
                  slug
                  code
                  url
                }
              }
              ... on listSteps {
                type
                isSlider
                steps {
                  type
                  ... on step {
                    type
                    image {
                      url
                      alt
                    }
                    imageStyles {
                      style
                      link
                    }
                    text
                  }
                }
              }
              ... on text {
                type
                title
                slug
                text
              }
              ... on tableOfContents {
                type
                title
                text
                links {
                  type
                  ... on linkTdm {
                    type
                    typeLink
                    text
                    url
                    level
                  }
                }
              }
              ... on youtube {
                type
                uuid
                finalTitle
                finalSlug
                titleParagraph
                slugParagraph
                video {
                  uuid
                  title
                  code
                  image {
                    url
                    alt
                  }
                  imageStyles {
                    style
                    link
                  }
                  description
                  videoId
                  tags
                }
              }
              ... on media {
                type
                title
                hasDecoration
                description
                source
                legend
                image {
                  url
                  alt
                }
                imageStyles {
                  style
                  link
                }
              }
              ... on multiColumn {
                type
                totalColumns
                format
                column1 {
                  type
                  ... on text {
                    type
                    title
                    slug
                    text
                  }
                  ... on media {
                    type
                    title
                    hasDecoration
                    description
                    source
                    legend
                    image {
                      url
                      alt
                    }
                    imageStyles {
                      style
                      link
                    }
                  }
                  ... on tableOfContents {
                    type
                    title
                    text
                    links {
                      type
                      ... on linkTdm {
                        type
                        typeLink
                        text
                        url
                        level
                      }
                    }
                  }
                  ... on youtube {
                    type
                    uuid
                    finalTitle
                    finalSlug
                    titleParagraph
                    slugParagraph
                    video {
                      uuid
                      title
                      code
                      image {
                        url
                        alt
                      }
                      imageStyles {
                        style
                        link
                      }
                      description
                      videoId
                      tags
                    }
                  }
                }
                column2 {
                  type
                  ... on text {
                    type
                    title
                    slug
                    text
                  }
                  ... on media {
                    type
                    title
                    hasDecoration
                    description
                    source
                    legend
                    image {
                      url
                      alt
                    }
                    imageStyles {
                      style
                      link
                    }
                  }
                  ... on tableOfContents {
                    type
                    title
                    text
                    links {
                      type
                      ... on linkTdm {
                        type
                        typeLink
                        text
                        url
                        level
                      }
                    }
                  }
                  ... on youtube {
                    type
                    uuid
                    finalTitle
                    finalSlug
                    titleParagraph
                    slugParagraph
                    video {
                      uuid
                      title
                      code
                      image {
                        url
                        alt
                      }
                      imageStyles {
                        style
                        link
                      }
                      description
                      videoId
                      tags
                    }
                  }
                }
                column3 {
                  type
                  ... on text {
                    type
                    title
                    slug
                    text
                  }
                  ... on media {
                    type
                    title
                    hasDecoration
                    description
                    source
                    legend
                    image {
                      url
                      alt
                    }
                    imageStyles {
                      style
                      link
                    }
                  }
                  ... on tableOfContents {
                    type
                    title
                    text
                    links {
                      type
                      ... on linkTdm {
                        type
                        typeLink
                        text
                        url
                        level
                      }
                    }
                  }
                  ... on youtube {
                    type
                    uuid
                    finalTitle
                    finalSlug
                    titleParagraph
                    slugParagraph
                    video {
                      uuid
                      title
                      code
                      image {
                        url
                        alt
                      }
                      imageStyles {
                        style
                        link
                      }
                      description
                      videoId
                      tags
                    }
                  }
                }
              }
            }
            solution {
              type
              ... on exerciseList {
                type
                uuid
                title
                slug
                totalColumns
                isSlider
                content {
                  type
                  ... on exercise {
                    type
                    uuid
                    finalTitle
                    finalSlug
                    titleParagraph
                    slugParagraph
                    exercise {
                      uuid
                      title
                      code
                      url
                      tags
                    }
                  }
                }
                exercises {
                  type
                  title
                  slug
                  code
                  url
                }
              }
              ... on listSteps {
                type
                isSlider
                steps {
                  type
                  ... on step {
                    type
                    image {
                      url
                      alt
                    }
                    imageStyles {
                      style
                      link
                    }
                    text
                  }
                }
              }
              ... on text {
                type
                title
                slug
                text
              }
              ... on tableOfContents {
                type
                title
                text
                links {
                  type
                  ... on linkTdm {
                    type
                    typeLink
                    text
                    url
                    level
                  }
                }
              }
              ... on youtube {
                type
                uuid
                finalTitle
                finalSlug
                titleParagraph
                slugParagraph
                video {
                  uuid
                  title
                  code
                  image {
                    url
                    alt
                  }
                  imageStyles {
                    style
                    link
                  }
                  description
                  videoId
                  tags
                }
              }
              ... on media {
                type
                title
                hasDecoration
                description
                source
                legend
                image {
                  url
                  alt
                }
                imageStyles {
                  style
                  link
                }
              }
              ... on multiColumn {
                type
                totalColumns
                format
                column1 {
                  type
                  ... on text {
                    type
                    title
                    slug
                    text
                  }
                  ... on media {
                    type
                    title
                    hasDecoration
                    description
                    source
                    legend
                    image {
                      url
                      alt
                    }
                    imageStyles {
                      style
                      link
                    }
                  }
                  ... on tableOfContents {
                    type
                    title
                    text
                    links {
                      type
                      ... on linkTdm {
                        type
                        typeLink
                        text
                        url
                        level
                      }
                    }
                  }
                  ... on youtube {
                    type
                    uuid
                    finalTitle
                    finalSlug
                    titleParagraph
                    slugParagraph
                    video {
                      uuid
                      title
                      code
                      image {
                        url
                        alt
                      }
                      imageStyles {
                        style
                        link
                      }
                      description
                      videoId
                      tags
                    }
                  }
                }
                column2 {
                  type
                  ... on text {
                    type
                    title
                    slug
                    text
                  }
                  ... on media {
                    type
                    title
                    hasDecoration
                    description
                    source
                    legend
                    image {
                      url
                      alt
                    }
                    imageStyles {
                      style
                      link
                    }
                  }
                  ... on tableOfContents {
                    type
                    title
                    text
                    links {
                      type
                      ... on linkTdm {
                        type
                        typeLink
                        text
                        url
                        level
                      }
                    }
                  }
                  ... on youtube {
                    type
                    uuid
                    finalTitle
                    finalSlug
                    titleParagraph
                    slugParagraph
                    video {
                      uuid
                      title
                      code
                      image {
                        url
                        alt
                      }
                      imageStyles {
                        style
                        link
                      }
                      description
                      videoId
                      tags
                    }
                  }
                }
                column3 {
                  type
                  ... on text {
                    type
                    title
                    slug
                    text
                  }
                  ... on media {
                    type
                    title
                    hasDecoration
                    description
                    source
                    legend
                    image {
                      url
                      alt
                    }
                    imageStyles {
                      style
                      link
                    }
                  }
                  ... on tableOfContents {
                    type
                    title
                    text
                    links {
                      type
                      ... on linkTdm {
                        type
                        typeLink
                        text
                        url
                        level
                      }
                    }
                  }
                  ... on youtube {
                    type
                    uuid
                    finalTitle
                    finalSlug
                    titleParagraph
                    slugParagraph
                    video {
                      uuid
                      title
                      code
                      image {
                        url
                        alt
                      }
                      imageStyles {
                        style
                        link
                      }
                      description
                      videoId
                      tags
                    }
                  }
                }
              }
            }
          }
          ... on multiColumn {
            type
            totalColumns
            format
            column1 {
              type
              ... on text {
                type
                title
                slug
                text
              }
              ... on media {
                type
                title
                source
                hasDecoration
                description
                legend
                image {
                  url
                  alt
                }
                imageStyles {
                  style
                  link
                }
              }
              ... on youtube {
                type
                uuid
                finalTitle
                finalSlug
                titleParagraph
                slugParagraph
                video {
                  uuid
                  title
                  code
                  image {
                    url
                    alt
                  }
                  imageStyles {
                    style
                    link
                  }
                  description
                  videoId
                  tags
                }
              }
              ... on tableOfContents {
                type
                title
                text
                links {
                  type
                  ... on linkTdm {
                    type
                    typeLink
                    text
                    url
                    level
                  }
                }
              }
            }
            column2 {
              type
              ... on text {
                type
                title
                slug
                text
              }
              ... on media {
                type
                title
                hasDecoration
                description
                source
                legend
                image {
                  url
                  alt
                }
                imageStyles {
                  style
                  link
                }
              }
              ... on youtube {
                type
                uuid
                finalTitle
                finalSlug
                titleParagraph
                slugParagraph
                video {
                  uuid
                  code
                  title
                  code
                  image {
                    url
                    alt
                  }
                  imageStyles {
                    style
                    link
                  }
                  description
                  videoId
                  tags
                }
              }
              ... on tableOfContents {
                type
                title
                text
                links {
                  type
                  ... on linkTdm {
                    type
                    typeLink
                    text
                    url
                    level
                  }
                }
              }
            }
            column3 {
              type
              ... on text {
                type
                title
                slug
                text
              }
              ... on media {
                type
                title
                hasDecoration
                description
                source
                legend
                image {
                  url
                  alt
                }
                imageStyles {
                  style
                  link
                }
              }
              ... on tableOfContents {
                type
                title
                text
                links {
                  type
                  ... on linkTdm {
                    type
                    typeLink
                    text
                    url
                    level
                  }
                }
              }
              ... on youtube {
                type
                uuid
                finalTitle
                finalSlug
                titleParagraph
                slugParagraph
                video {
                  uuid
                  title
                  code
                  image {
                    url
                    alt
                  }
                  imageStyles {
                    style
                    link
                  }
                  description
                  videoId
                  tags
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export {
  getFileFullGQL
};
