<div class="flo-limbs">

  <!-- LEFT ARM-->
  <div class="flo-limbs__arm flo-limbs__arm--left">
    <div
      class="flo-limbs__bicep"
      [ngStyle]="{
        'transform': 'rotate3d(0, 0, 1, ' + limbs.bicepR + 'deg)'
      }"
    >
      <div
        class="flo-limbs__forearm"
        [ngStyle]="{
          'transform': 'rotate3d(0, 0, 1, ' + limbs.forearmR + 'deg)'
        }"
      >
        <div
          class="flo-limbs__hand"
          [ngStyle]="{
            'transform': 'rotate3d(0, 0, 1, ' + limbs.handR + 'deg) scaleX(' + limbs.handScaleR + ') translate3d('+ limbs.handRPos+'%, 0, 0)'
          }"
        >

          <div
            class="flo-limbs__palm"
            *ngIf="limbs.handTypeR === 'palm'"
          >
            <div class="palm__thumb"></div>
          </div>

          <svg
            class="flo-limbs__finger"
            *ngIf="limbs.handTypeR === 'finger'"

            viewBox="0 0 174.3 196.24"
          >
            <path fill="#05bf8e" d="M47.54,196.24c-29,0-47-12-47-30,0-2.49,0-10.37-.06-11.66C.05,132.7-.24,124.69.27,113.44.58,106.54,1,101.26,3,95a61.45,61.45,0,0,1,3.78-9.41c6.52-13,17-19.1,31-19.94,3.63-.21,7.3.21,10.94.34,1.59,15.53,1,31.14,1.63,46.7.8,21.22-3,22.65-22.51,26-8.76,1.53-17.49.37-25.46-5.88,0,3.31,0,5.95,0,8.6.14,8.11,2.34,11,10.23,11.57,11.27.76,22.49.57,33.44-3.32,12.81-4.56,18.68-15.23,18.34-25.49-.54-16.27-.17-32.58-.14-48.87,0-3.34.11-6.66-1.85-9.61,2.42-3.25,1.55-7,1.57-10.62.07-13.32-.06-26.64.06-40C64.1,6.43,67.94,1.68,75.6.25c7-1.3,13.64,2.58,16.76,9.68,1.94,4.41,1.93,9.05,2,13.73q.32,44,.69,87.9c0,1,0,2,0,3,.14,10,.14,10,9.7,10,15.65.11,31.3.12,47,.26a49.82,49.82,0,0,1,9.93.71c8,1.71,12.92,8.05,12.67,15.67-.24,7.21-5,12.82-12.74,14.47a61,61,0,0,1-10.89,1.12c-11.65.34-23.3.49-34.95.74a55.86,55.86,0,0,0-7,.44c-10.95,1.65-12.61,4.06-12.9,14.65S76.54,196.24,47.54,196.24Z"/>
            <path fill="#00663e" d="M62.4,65.71c2,3,1.86,6.27,1.85,9.61,0,16.29-.4,32.6.14,48.87.34,10.26-5.53,20.93-18.34,25.49-10.95,3.89-22.17,4.08-33.44,3.32-7.89-.53-10.09-3.46-10.23-11.57,0-2.65,0-5.29,0-8.6,8,6.25,16.7,7.41,25.46,5.88,19.48-3.39,23.31-4.82,22.51-26-.59-15.56,0-31.17-1.63-46.7Z"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
  <!-- END LEFT ARM-->


  <!-- RIGHT ARM-->
  <div class="flo-limbs__arm flo-limbs__arm--right">
    <div
      class="flo-limbs__bicep"
      [ngStyle]="{
        'transform': 'scale3d(-1, 1, 1) rotate3d(0, 0, 1, ' + limbs.bicepL + 'deg)'
      }"
    >
      <div
        class="flo-limbs__forearm"
        [ngStyle]="{
          'transform': 'rotate3d(0, 0, 1, ' + limbs.forearmL + 'deg)'
        }"
      >
        <div class="flo-limbs__hand"
          [ngStyle]="{
            'transform': 'rotate3d(0, 0, 1, ' + limbs.handL + 'deg) scaleX(' + limbs.handScaleL + ') translate3d('+ limbs.handLPos+'%, 0, 0)'
          }"
        >
        <div
          class="flo-limbs__palm"
          *ngIf="limbs.handTypeL === 'palm'"
        >
          <div class="palm__thumb"></div>
        </div>

          <svg
            class="flo-limbs__finger"
            *ngIf="limbs.handTypeL === 'finger'"
            viewBox="0 0 174.3 196.24"
          >
            <path fill="#05bf8e" d="M47.54,196.24c-29,0-47-12-47-30,0-2.49,0-10.37-.06-11.66C.05,132.7-.24,124.69.27,113.44.58,106.54,1,101.26,3,95a61.45,61.45,0,0,1,3.78-9.41c6.52-13,17-19.1,31-19.94,3.63-.21,7.3.21,10.94.34,1.59,15.53,1,31.14,1.63,46.7.8,21.22-3,22.65-22.51,26-8.76,1.53-17.49.37-25.46-5.88,0,3.31,0,5.95,0,8.6.14,8.11,2.34,11,10.23,11.57,11.27.76,22.49.57,33.44-3.32,12.81-4.56,18.68-15.23,18.34-25.49-.54-16.27-.17-32.58-.14-48.87,0-3.34.11-6.66-1.85-9.61,2.42-3.25,1.55-7,1.57-10.62.07-13.32-.06-26.64.06-40C64.1,6.43,67.94,1.68,75.6.25c7-1.3,13.64,2.58,16.76,9.68,1.94,4.41,1.93,9.05,2,13.73q.32,44,.69,87.9c0,1,0,2,0,3,.14,10,.14,10,9.7,10,15.65.11,31.3.12,47,.26a49.82,49.82,0,0,1,9.93.71c8,1.71,12.92,8.05,12.67,15.67-.24,7.21-5,12.82-12.74,14.47a61,61,0,0,1-10.89,1.12c-11.65.34-23.3.49-34.95.74a55.86,55.86,0,0,0-7,.44c-10.95,1.65-12.61,4.06-12.9,14.65S76.54,196.24,47.54,196.24Z"/>
            <path fill="#00663e" d="M62.4,65.71c2,3,1.86,6.27,1.85,9.61,0,16.29-.4,32.6.14,48.87.34,10.26-5.53,20.93-18.34,25.49-10.95,3.89-22.17,4.08-33.44,3.32-7.89-.53-10.09-3.46-10.23-11.57,0-2.65,0-5.29,0-8.6,8,6.25,16.7,7.41,25.46,5.88,19.48-3.39,23.31-4.82,22.51-26-.59-15.56,0-31.17-1.63-46.7Z"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
  <!-- END RIGHT ARM-->


  <!-- LEGS-->
  <svg
    class="flo-limbs__leg flo-limbs__leg--left"
    [ngClass]="{
      'state-flo-minimized': minimized,
      'state-flo-asleep': asleep
    }"
    viewBox="0 0 184 687.44"
  >
  <path d="M184 .44l-2.01 675.67c0 6.23-5.1 11.33-11.33 11.33H20.86C9.39 687.44 0 678.06 0 666.59v-8.51c0-11.9 9.73-21.63 21.63-21.63h79.64a3.73 3.73 0 003.73-3.73L104 .44c66-1 0 0 80 0z" fill-rule="evenodd" clip-rule="evenodd" fill="#05bf8e"/>
</svg>
  <svg
    class="flo-limbs__leg flo-limbs__leg--right"
    [ngClass]="{
      'state-flo-minimized': minimized,
      'state-flo-asleep': asleep
    }"
    viewBox="0 0 184 687.44"
  >
  <path d="M184 .44l-2.01 675.67c0 6.23-5.1 11.33-11.33 11.33H20.86C9.39 687.44 0 678.06 0 666.59v-8.51c0-11.9 9.73-21.63 21.63-21.63h79.64a3.73 3.73 0 003.73-3.73L104 .44c66-1 0 0 80 0z" fill-rule="evenodd" clip-rule="evenodd" fill="#05bf8e"/>
</svg>
  <!-- END LEGS-->
</div>
