import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ToolsService } from '@app/services/services';
import { Router } from '@angular/router';
import { TopicVal, FileTypeVal  } from '@app/shared/enums';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss']
})
export class TagListComponent implements OnInit {
  @Input() tags: string[];
  @Input() hub: string  = null;
  @Input() focusable: boolean;
  @Input() buttonBehaviour: string = null; // if value  === nav -> navigate to new page

  @Output() onClick = new EventEmitter<object>();

  hubVal: string;
  taglimit: number;

  constructor(
    private tools: ToolsService,
    private router: Router,
    private translate: TranslateService

  ) {
    this.taglimit = 10;
  }

  onTagClick(event, value) {
    this.onClick.emit({
      event,
      value
    });

    if (this.buttonBehaviour === 'nav') {
      this.router.navigate(['/bv/' + this.hubVal], { queryParams: { keywords: value } });
    }
  }

  ngOnInit() {

    if (TopicVal.hasOwnProperty(this.hub)) {
      this.hubVal = this.tools.getTopicVal(this.hub);
    } else if (FileTypeVal.hasOwnProperty(this.hub)) {
      this.hubVal = this.translate.instant(
        `Common.Library.ReverseRoutes.${this.hub}`
      );
    }
  }

  onClickShowMore() {
    this.taglimit = this.tags.length;
  }
}
