export const environment = {
  production: false,
  enableProdMode: true,
  hmr: false,
  key: null,
  firebase: {
    apiKey: 'AIzaSyD-IMtC9qzGyTnU9CCi297yxw3EUJlrR-M',
    authDomain: 'alloprof-prod.firebaseapp.com',
    databaseURL: 'https://alloprof-prod.firebaseio.com',
    projectId: 'alloprof-prod',
    storageBucket: 'alloprof-prod.appspot.com',
    messagingSenderId: '75722865043',
    appId: '1:75722865043:web:3ce1dbd50cdc3efcc15066'
  },
  functionsUrl: 'https://alloprof-prod.web.app/v1',
  ssoSigninUrl: 'https://alloprof-prod.web.app/v1/auth/signin',
  graphQlUrl: 'https://graphql-dot-alloprof-prod.appspot.com/graphql',
  searchUrl: 'https://search-dot-alloprof-prod.appspot.com/v1/search/'
};
