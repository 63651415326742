import { Component, OnInit, OnDestroy } from '@angular/core';
import { EventsService, RafService, ToolsService } from '@app/services/services';
import { mouthExpressions } from '@app/shared/components/flo/flo.presets';
import { Face, Limbs } from '@app/shared/components/flo/flo.types';
import { facePresets, bodyPresets } from '../flo.presets';
import { interpolate } from 'flubber';
import gsap from 'gsap';

const BLINK_DURATION = 14; // frames
const MIN_BLINK_DELAY = 40; // frames
const MAX_BLINK_DELAY = 400; // frames!
const HIDDEN_DISTANCE = 200;

@Component({
  selector: 'app-search-flo',
  templateUrl: './search-flo.component.html',
  styleUrls: ['./search-flo.component.scss']
})
export class SearchFloComponent implements OnInit, OnDestroy {
  animation: string;
  blinkNext: number;
  classes: string;
  face: Face;
  searchOpen: boolean;
  follows: boolean;
  interpolate: Face;
  mouthStart: number;
  mouthInterpolate: interpolate | false;
  limbs: Limbs;
  posX: any;

  tlEnterFlo: GSAPTimeline;
  tlLeaveFlo: GSAPTimeline;
  tlErrorFlo: GSAPTimeline;
  tlValidationFlo: GSAPTimeline;

  constructor(
    private events: EventsService,
    private raf: RafService,
    private tools: ToolsService) {
    this.animation = '';
    this.blinkNext = 0;
    this.searchOpen = false;
    this.classes = '';
    this.follows = false;
    this.face = { ...facePresets.default, ...facePresets.enterSearch };
    this.limbs = { ...bodyPresets.default };
    this.interpolate = { ...this.face };

    this.posX = {
      value: HIDDEN_DISTANCE
    };
  }

  ngOnInit() {
    this.events.on('search:open', this.onSearchOpen);
    this.events.on('search:close-flo', this.onSearchClose);
    this.events.on('flo:search:animate', this.setAnimation);
    this.setupTimelines();
  }

  ngOnDestroy() {
    this.unfollow();
    this.events.off('search:open', this.onSearchOpen);
    this.events.off('search:close-flo', this.onSearchClose);
    this.events.off('flo:search:animate', this.setAnimation);
  }

  /**
   * Mouse cursor follow behavior
   */

  followCursor = (e) => {
    if (!this.follows) {
      return;
    }

    const { clientX, clientY } = e;
    const { innerWidth, innerHeight } = window;
    const ratioX = 0.8;
    const ratioY = 0.5;

    this.interpolate.facePositionX = (clientX / innerWidth - ratioX) * 15;
    this.interpolate.facePositionY = (clientY / innerHeight - ratioY) * 15;
    this.interpolate.retinasPositionX = (clientX / innerWidth - ratioX) * 20;
    this.interpolate.retinasPositionY = (clientY / innerHeight - ratioY) * 20;
    this.interpolate.bodyRotation = (0.75 - clientY / innerHeight) * 2;
    this.interpolate.finRotate = (clientY / innerHeight) * -100;
  }

  follow() {
    this.follows = true;
    document.addEventListener('mousemove', this.followCursor);
  }

  unfollow() {
    this.follows = false;
    document.removeEventListener('mousemove', this.followCursor);
  }

  /**
   * Animations
   */

  setAnimation = (type) => {
    //this.tlEnterFlo.pause();
    this.tlValidationFlo.restart().pause();
    this.tlErrorFlo.restart().pause();

    this.animation = type;

    switch (type) {
      case 'hasResults':
        this.tlValidationFlo.restart();
        break;
      case 'notFound':
        this.tlErrorFlo.restart();
        break;
    }
  }

  /**
   * Events
   */

  onSearchOpen = () => {
    this.searchOpen = true;
    this.raf.bind('search-flo', this.draw.bind(this));
    //this.tlLeaveFlo.restart().pause();
    this.tlEnterFlo.restart();

    this.animation = '';

  }
  onSearchClose = () => {
    this.tlEnterFlo.pause();
    this.tlLeaveFlo.restart();
    this.animation = '';
  }

  /**
   * RAF METHODS
   */

  getNextBlinkFrame() {
    const { raf } = this.raf.instance;
    this.blinkNext = raf + this.tools.getRandomInt(MIN_BLINK_DELAY, MAX_BLINK_DELAY);
  }
  getBlinkState() {
    const { raf } = this.raf.instance;
    const blinkEnd = this.blinkNext + BLINK_DURATION;

    if (!this.blinkNext || raf >= blinkEnd) {
      this.getNextBlinkFrame();
    }

    return (raf >= this.blinkNext && raf <= blinkEnd) ? 0 : 1;
  }

  animateMouth() {
    if (this.face.mouthExpression === this.interpolate.mouthExpression) {
      return;
    }
    if (!this.mouthStart) {
      this.mouthStart = this.raf.instance.raf;
      this.mouthInterpolate = interpolate(this.face.mouthExpression, this.interpolate.mouthExpression);
    }
    const delta = this.raf.instance.raf - this.mouthStart;
    const duration = 10;
    const progress = delta / duration;

    if (delta === duration) {
      this.mouthStart = 0;
      this.face.mouthExpression = this.interpolate.mouthExpression;
      return;
    }

    this.face.mouthExpression = this.mouthInterpolate(progress);
  }

  animateFace() {
    const { raf } = this.raf.instance;
    const bob = this.follows ? Math.cos(raf * 0.02) * 0.3 : 0;
    const eyelidBlink = this.getBlinkState();

    this.face.bodyRotation += (this.interpolate.bodyRotation - this.face.bodyRotation) * 0.04;
    this.face.eyebrowDistance += (this.interpolate.eyebrowDistance - this.face.eyebrowDistance) * 0.2;
    this.face.eyebrowRotationL += (this.interpolate.eyebrowRotationL - this.face.eyebrowRotationL) * 0.3;
    this.face.eyebrowRotationR += (this.interpolate.eyebrowRotationR - this.face.eyebrowRotationR) * 0.3;
    this.face.eyelidR += ((eyelidBlink * this.interpolate.eyelidR) - this.face.eyelidR) * 0.5;
    this.face.eyelidL += ((eyelidBlink * this.interpolate.eyelidL) - this.face.eyelidL) * 0.5;
    this.face.upperEyelidR += (this.interpolate.upperEyelidR - this.face.upperEyelidR) * 0.5;
    this.face.upperEyelidL += (this.interpolate.upperEyelidL - this.face.upperEyelidL) * 0.5;
    this.face.retinaScale += (this.interpolate.retinaScale - this.face.retinaScale) * 0.08;
    this.face.retinasPositionX += (this.interpolate.retinasPositionX - this.face.retinasPositionX) * 0.3;
    this.face.retinasPositionY += (this.interpolate.retinasPositionY - this.face.retinasPositionY) * 0.3;
    this.face.facePositionX += (this.interpolate.facePositionX - this.face.facePositionX) * 0.08;
    this.face.facePositionY += (this.interpolate.facePositionY - this.face.facePositionY) * 0.08 + bob;
  }

  /**
   * Draw Loop
   */

  draw() {
    this.animateFace();
    this.animateMouth();
  }

  /**
   * TWEEN ANIMATION SETUP METHODS
   */

  setupTimelineError() {
    this.tlErrorFlo = gsap.timeline({
      paused: true,
      onStart: () => {
        this.tlLeaveFlo.pause();
        this.tlValidationFlo.pause();
        this.tlEnterFlo.pause();

        this.limbs.handTypeR = 'palm';
        this.face.eyebrowExpressionR = 'curved';
        this.face.eyebrowExpressionL = 'curved';
        this.face.eyeClosedType = 'squint';
        this.limbs.handScaleR = -1;
        this.limbs.handRPos = 0;

        this.interpolate = {
          ...this.interpolate,
          ...facePresets.default,
          eyelidR: 0,
          eyelidL: 0,
          upperEyelidR: 1,
          upperEyelidL: 1,
          eyebrowDistance: 15,
          eyebrowRotationR: -30,
          eyebrowRotationL: 30,
          mouthExpression: mouthExpressions.crying
        };
      },
      onComplete: () => {
        this.limbs.handScaleR = -1;
        this.limbs.handRPos = 0;
        this.tlErrorFlo.pause();
        this.animation = '';
        this.face.eyeClosedType = 'curvedDown';
      }
    });

    this.tlErrorFlo.fromTo(this.limbs, { bicepL: bodyPresets.default.bicepL }, { duration: .8, bicepL: -35, ease: 'power3.out' });
    this.tlErrorFlo.fromTo(this.limbs, { bicepR: bodyPresets.default.bicepR }, { duration: .8, bicepR: -35, ease: 'power3.out' }, 0);

    this.tlErrorFlo.fromTo(this.limbs, { forearmL: bodyPresets.default.forearmL }, { duration: .8, forearmL: 108, ease: 'power3.out' }, 0);
    this.tlErrorFlo.fromTo(this.limbs, { forearmR: bodyPresets.default.forearmR }, { duration: .8, forearmR: 108, ease: 'power3.out' }, 0);

    this.tlErrorFlo.fromTo(this.limbs, { handL: bodyPresets.default.handL }, { duration: .8, handL: -20, ease: 'power3.out' }, 0);
    this.tlErrorFlo.fromTo(this.limbs, { handR: bodyPresets.default.handR }, { duration: .8, handR: -20, ease: 'power3.out' }, 0);

    this.tlErrorFlo.fromTo(this.limbs, { handLPos: bodyPresets.default.handLPos }, { duration: .8, handLPos: -10, ease: 'power3.out' }, 0);
    this.tlErrorFlo.fromTo(this.limbs, { handRPos: bodyPresets.default.handRPos }, { duration: .8, handRPos: -10, ease: 'power3.out' }, 0);

    this.tlErrorFlo.to(this.limbs, 1.8, {
      bicepL: bodyPresets.default.bicepL, ease: 'elastic.out(0.6, 0.75)', onStart: () => {
        this.interpolate = {
          ...facePresets.default,
        };
      }
    }, 2);

    this.tlErrorFlo.to(this.limbs, 1.8, { bicepR: bodyPresets.default.bicepR, ease: 'elastic.out(0.6, 0.75)' }, 2);

    this.tlErrorFlo.to(this.limbs, .8, { forearmL: bodyPresets.default.forearmL, ease: 'power3.out' }, 2);
    this.tlErrorFlo.to(this.limbs, .8, { forearmR: bodyPresets.default.forearmR, ease: 'power3.out' }, 2);

    this.tlErrorFlo.to(this.limbs, .8, { handL: bodyPresets.default.handL, ease: 'power3.out' }, 2);
    this.tlErrorFlo.to(this.limbs, .8, { handR: bodyPresets.default.handR, ease: 'power3.out' }, 2);

    this.tlErrorFlo.to(this.limbs, .8, { handLPos: bodyPresets.default.handLPos, ease: 'power3.out' }, 2);
    this.tlErrorFlo.to(this.limbs, .8, { handRPos: bodyPresets.default.handRPos, ease: 'power3.out' }, 2);
  }



  setupTimelineValidate() {
    this.tlValidationFlo = gsap.timeline({
      paused: true,
      onStart: () => {

        this.tlLeaveFlo.pause();
        this.tlErrorFlo.pause();
        this.tlEnterFlo.pause();

        this.interpolate = {
          ...facePresets.default,
          mouthExpression : mouthExpressions.smirk,
          eyebrowRotationL : -5

        };

        this.face.eyebrowExpressionL = 'wave';
        this.face.eyeClosedType = 'curvedDown';
        this.limbs.handTypeR = 'finger';
        this.limbs.handScaleR = 1;
        this.limbs.handRPos = 0;

      },
      onComplete: () => {
        this.limbs.handScaleR = -1;
        this.limbs.handRPos = 0;
        this.tlValidationFlo.pause();
        this.animation = '';
      }
    });

    this.tlValidationFlo.fromTo(this.limbs, { bicepL: bodyPresets.default.bicepL }, { duration: .8, bicepL: -117, ease: 'power3.out' });
    this.tlValidationFlo.fromTo(this.limbs, { forearmL: bodyPresets.default.forearmL }, { duration: .8, forearmL: -115, ease: 'power3.out' }, 0);
    this.tlValidationFlo.fromTo(this.limbs, { handL: bodyPresets.default.handL }, { duration: .8, handL: 50, ease: 'power3.out' }, 0);
    this.tlValidationFlo.fromTo(this.limbs, { handLPos: bodyPresets.default.handLPos }, { duration: .8, handLPos: 10, ease: 'power3.out' }, 0);

    this.tlValidationFlo.fromTo(this.limbs, { forearmR: bodyPresets.default.forearmR }, { duration: .8, forearmR: 90, ease: 'power3.out' }, 0);

    this.tlValidationFlo.to(this.limbs, 1.8, { bicepL: bodyPresets.default.bicepL, ease: 'elastic.out(0.6, 0.75)' }, 2);

    this.tlValidationFlo.to(this.limbs, .8, { forearmL: bodyPresets.default.forearmL, ease: 'power3.out' }, 2);
    this.tlValidationFlo.to(this.limbs, .8, { handL: bodyPresets.default.handL, ease: 'power3.out' }, 2);
    this.tlValidationFlo.to(this.limbs, .8, {
      handLPos: bodyPresets.default.handLPos, ease: 'power3.out', onStart: () => {

        this.face.eyebrowExpressionL = 'curved';
        this.limbs.handTypeR = 'palm';
        this.limbs.handScaleR = -1;
        this.limbs.handRPos = 0;

        this.interpolate = {
          ...facePresets.default,
        };
      }
    }, 2);

    this.tlValidationFlo.to(this.limbs, 1.8, { forearmR: bodyPresets.default.forearmR, ease: 'elastic.out(0.6, 0.75)' }, 2);
  }

  setupTimelineEnter() {
    this.tlEnterFlo = gsap.timeline({
      paused: true,
      onStart: () => {
        this.tlLeaveFlo.pause();
        this.tlErrorFlo.pause();
        this.tlValidationFlo.pause();

        this.face.eyeClosedType = 'curvedDown';
      },
      onComplete: () => {
        this.tlLeaveFlo.pause(); // restart anim onclose to avoid bad surprise
        this.interpolate.mouthExpression = mouthExpressions.neutralSmile;
      }
    });

    this.tlEnterFlo.fromTo(this.posX, { value: HIDDEN_DISTANCE }, {
      duration: 1, value: 0, ease: 'power4.out', onComplete: () => {
        this.interpolate.mouthExpression = mouthExpressions.neutralSmile;
        this.interpolate.eyebrowDistance = 15;
        this.interpolate.upperEyelidL = 1.4;
        this.interpolate.upperEyelidR = 1.4;
      }
    }, 1);

    // face anim
    this.tlEnterFlo.fromTo(this.face,
      { bodyRotation: facePresets.default.bodyRotation },
      {
        duration: 1, bodyRotation: 10, ease: 'power4.out',
        onComplete: () => {
          this.follow();
        }
      },
      1.2);

    // Flo says hi

    this.tlEnterFlo.fromTo(this.limbs, { bicepR: bodyPresets.default.bicepR }, { duration: .5, bicepR: -60, ease: 'power4.out' }, 1.5);
    this.tlEnterFlo.fromTo(this.limbs, { forearmR: bodyPresets.default.forearmR }, { duration: .5, forearmR: 65, ease: 'power4.out' }, 1.5);
    this.tlEnterFlo.fromTo(this.limbs, { handScaleR: bodyPresets.default.handScaleR }, { duration: 0, handScaleR: 1, ease: 'power4.out' }, 1.5);

    this.tlEnterFlo.fromTo(this.limbs, { handR: bodyPresets.default.handR }, { duration: .2, handR: -15, ease: 'sine.inOut' }, 1.8);
    this.tlEnterFlo.to(this.limbs, .2, { handR: 15, ease: 'sine.inOut' });
    this.tlEnterFlo.to(this.limbs, .2, { handR: -15, ease: 'sine.inOut' });
    this.tlEnterFlo.to(this.limbs, .2, {
      handR: 0, ease: 'circ.inOut', onStart: () => {
        this.limbs.handScaleR = bodyPresets.default.handScaleR;
      }
    }, 2.4);
    this.tlEnterFlo.to(this.limbs, 2, { bicepR: bodyPresets.default.bicepR, ease: 'elastic.out(0.6, 0.75)' }, 2.1);
    this.tlEnterFlo.to(this.limbs, .5, { forearmR: bodyPresets.default.forearmR, ease: 'power4.out' }, 2.1);
  }

  setupTimelineLeave() {
    this.tlLeaveFlo = gsap.timeline({
      paused: true,
      onStart: () => {
        this.face.eyeClosedType = 'curvedDown';
        this.interpolate.mouthExpression = mouthExpressions.oFace;

        this.tlErrorFlo.pause();
        this.tlValidationFlo.pause();
        this.tlEnterFlo.pause();
      },
      onComplete: () => {
        this.unfollow();

        this.tlLeaveFlo.pause();
        this.tlErrorFlo.pause();
        this.tlValidationFlo.pause();
        this.tlEnterFlo.pause();

        this.interpolate = {
          ...facePresets.default,
          ...facePresets.enterSearch
        };

        this.raf.unbind('search-flo');
      }
    });
    this.tlLeaveFlo.to(this.posX, 1, { value: HIDDEN_DISTANCE, ease: 'power3.out' });
  }

  setupTimelines() {
    this.setupTimelineLeave();
    this.setupTimelineValidate();
    this.setupTimelineError();
    this.setupTimelineEnter();
  }
}
