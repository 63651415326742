<div
  class="overlay"
  [ngClass]="{
    'overlay--open': active
  }"
  #menu
>
  <div class="nav nav--students">
    <app-base-section [isWrapping]="'main-menu'">
      <div class="nav__content">
        <div class="nav__content__cta">
          <a
            class="nav__close"
            (click)="onClose()"
            data-test="animationCloseStudentsMenu"
          >
            <span class="icon icon-close icon--tilt"></span>
          </a>
          <span class="nav__home">
            <a
              data-test="header-site"
              class="hover__icon"
              [routerLink]="'/'"
              (click)="onClose()"
              title="{{
                'Common.Menu.' + tools.state + '_switch_site' | translate
              }}"
            >
              <span class="icon icon-home"></span>
              <span class="icon icon-home-full hover__icon__active"></span>
            </a>
          </span>
        </div>
        <div class="nav__wrap">
          <div
            class="title title--h4 nav__content-available"
            *ngIf="translate.currentLang === 'en'"
          >
            Content only available in French
          </div>
          <div class="nav__wrap__top">

            <app-hub-redirect [listItems]="list" [classModifier]="'menu'" [isThemed]="true"></app-hub-redirect>

            <ng-container>
              <div class="nav__links is-desktop">
                <div class="nav__links__col">
                  <a
                    data-test="menu-students-exercises"
                    class="link subtitle--two"
                    [routerLink]="'/bv/exercices'"
                    ><span class="link__text">{{
                      'Common.Menu.exercises' | translate
                    }}</span></a
                  >
                  <a
                    data-test="menu-students-games"
                    class="link subtitle--two"
                    [routerLink]="'/bv/jeux'"
                    ><span class="link__text">{{
                      'Common.Menu.games' | translate
                    }}</span></a
                  >
                  <a
                    data-test="menu-students-cyberclass"
                    class="link subtitle--two"
                    [href]="tools.getConfig('links').cyberclasse"
                    target="_blank"
                    ><span class="link__text">{{
                      'Common.Menu.talk_to_teacher' | translate
                    }}</span></a
                  >
                  <a
                    data-test="menu-students-revisions"
                    class="link subtitle--two"
                    [routerLink]="'/bv/revisions'"
                    ><span class="link__text">{{
                      'Common.Menu.revisions' | translate
                    }}</span></a
                  >
                </div>
                <div class="nav__links__col links__body1">
                  <a
                    data-test="menu-students-parler"
                    class="link"
                    [href]="tools.getConfig('links').zoneTeacher"
                    target="_blank"
                    ><span class="link__text">{{
                      'Common.Menu.zone_teacher' | translate
                    }}</span></a
                  >
                  <a
                    data-test="menu-students-forums"
                    class="link"
                    [href]="tools.getConfig('links').forums"
                    target="_blank"
                    ><span class="link__text">{{
                      'Common.Menu.forums' | translate
                    }}</span></a
                  >
                  <a
                    data-test="menu-students-comm"
                    class="link"
                    [routerLink]="'bv/communautes_autochtones'"
                    ><span class="link__text">{{
                      'Common.Menu.comm' | translate
                    }}</span></a
                  >
                  <!--
                    FIXME : commented for launch
                     <a
                    data-test="menu-students-trucs"
                    class="link"
                    [routerLink]="'/trucs'"
                    ><span class="link__text">{{
                      'Common.Menu.trucs' | translate
                    }}</span></a
                  >
                   -->
                </div>
              </div>
              <div class="nav__links is-mobile">
                <div class="nav__links__col">
                  <ng-select
                    placeholder="{{
                      'Common.Menu.Students.title_col1' | translate
                    }}"
                    [searchable]="false"
                    [clearable]="false"
                    class="ng-select--clean"
                  >
                    <ng-option value="1">
                      <a
                        data-test="menu-students-exercises"
                        [routerLink]="'/bv/exercices'"
                        >{{ 'Common.Menu.exercises' | translate }}</a
                      >
                    </ng-option>
                    <ng-option value="2">
                      <a
                        data-test="menu-students-games"
                        [routerLink]="'/bv/jeux'"
                        >{{ 'Common.Menu.games' | translate }}</a
                      >
                    </ng-option>
                    <ng-option value="3">
                      <a
                        data-test="menu-students-cyberclass"
                        [href]="tools.getConfig('links').cyberclasse"
                        target="_blank"
                        >{{ 'Common.Menu.talk_to_teacher' | translate }}</a
                      >
                    </ng-option>
                    <ng-option value="4">
                      <a
                        data-test="menu-students-revisions"
                        [routerLink]="'/bv/revisions'"
                        >{{ 'Common.Menu.revisions' | translate }}</a
                      >
                    </ng-option>
                  </ng-select>
                </div>
                <div class="nav__links__col">
                  <ng-select
                    placeholder="{{
                      'Common.Menu.Students.title_col2' | translate
                    }}"
                    [searchable]="false"
                    [clearable]="false"
                    class="ng-select--clean"
                  >
                    <ng-option value="1">
                      <a
                        data-test="menu-students-parler"
                        [href]="tools.getConfig('links').zoneTeacher"
                        target="_blank"
                        >{{ 'Common.Menu.zone_teacher' | translate }}</a
                      >
                    </ng-option>
                    <ng-option value="2">
                      <a
                        data-test="menu-students-forums"
                        [href]="tools.getConfig('links').forums"
                        target="_blank"
                        >{{ 'Common.Menu.forums' | translate }}</a
                      >
                    </ng-option>
                    <ng-option value="3">
                      <a
                        data-test="menu-students-comm"
                        [routerLink]="'bv/communautes_autochtones'"
                        >{{ 'Common.Menu.comm' | translate }}</a
                      >
                    </ng-option >

                    <!--
                       FIXME : commented for launch
                      <ng-option value="3">
                      <a
                        data-test="menu-students-trucs"
                        [routerLink]="'/trucs'"
                        >{{ 'Common.Menu.trucs' | translate }}</a
                      ></ng-option
                    >
                     -->
                  </ng-select>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="buttom__actions">
            <a
              *ngIf="tools.getConfig('links').facebook"
              data-test="menu-students-social-fb"
              [href]="tools.getConfig('links').facebook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span class="icon icon-social-facebook icon--shrink"></span>
            </a>
            <a
              *ngIf="tools.getConfig('links').instagram"
              data-test="menu-students-social-instagram"
              [href]="tools.getConfig('links').instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span class="icon icon-social-instagram icon--shrink"></span>
            </a>
            <a
              *ngIf="tools.getConfig('links').youtube"
              data-test="menu-students-social-yt"
              [href]="tools.getConfig('links').youtube"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span class="icon icon-social-youtube icon--shrink"></span>
            </a>
            <a
              *ngIf="tools.getConfig('links').twitter"
              data-test="menu-students-social-twitter"
              [href]="tools.getConfig('links').twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span class="icon icon-social-twitter icon--shrink"></span>
            </a>
          </div>
        </div>
      </div>
      <div class="nav__flo">
        <img src="assets/images/temp/flo-menu.svg" alt="" />
      </div>
      <aside
        class="nav__link nav__link--students"
        data-theme="default"
      >
        <a class="title--h4" (click)="switchMenu()"
          ><span>{{
            'Common.Menu.Students.switch_section' | translate
          }}</span></a
        >
      </aside>
    </app-base-section>
  </div>

  <div class="nav nav--parents">
    <app-base-section [isWrapping]="'main-menu'">
      <div class="nav__content">
        <div class="nav__content__cta">
          <a
            class="nav__close"
            (click)="onClose()"
            id="animationCloseParentsMenu"
          >
            <span class="icon icon-close icon--tilt"></span>
          </a>
          <span class="nav__home">
            <a
              data-test="header-site"
              class="hover__icon"
              [routerLink]="'/parents'"
              (click)="onClose()"
              title="{{
                'Common.Menu.' + tools.state + '_switch_site' | translate
              }}"
            >
              <span class="icon icon-home"></span>
              <span class="icon icon-home-full hover__icon__active"></span>
            </a>
          </span>
        </div>
        <div class="nav__wrap">
          <div class="nav__wrap__top">
            <div class="grid grid--parents">
              <div class="grid--parents__links">
                <a class="title--h3 link" data-test="menu-parents-articles"
                  ><span class="link__text">{{
                    'Common.Menu.Parents.articles' | translate
                  }}</span></a
                >
                <a class="title--h3 link" data-test="menu-parents-chronicles"
                  ><span class="link__text">{{
                    'Common.Menu.Parents.chronicles' | translate
                  }}</span></a
                >
                <a class="title--h3 link" data-test="menu-parents-tools"
                  ><span class="link__text">{{
                    'Common.Menu.Parents.tools' | translate
                  }}</span></a
                >
                <a class="title--h3 link" data-test="menu-parents-tips"
                  ><span class="link__text">{{
                    'Common.Menu.Parents.tips' | translate
                  }}</span></a
                >
                <a class="title--h3 link" data-test="menu-parents-files"
                  ><span class="link__text">{{
                    'Common.Menu.Parents.files' | translate
                  }}</span></a
                >
              </div>
              <div>
                <div class="grid--parents__links is-desktop">
                  <a
                    class="link subtitle--one"
                    data-test="menu-parents-articles"
                  >
                    <span class="link__text">{{
                      'Common.Menu.Parents.articles' | translate
                    }}</span>
                  </a>
                  <a
                    class="link subtitle--one"
                    data-test="menu-parents-chronicles"
                  >
                    <span class="link__text">{{
                      'Common.Menu.Parents.chronicles' | translate
                    }}</span>
                  </a>
                  <a class="link subtitle--one" data-test="menu-parents-tools">
                    <span class="link__text">{{
                      'Common.Menu.Parents.tools' | translate
                    }}</span>
                  </a>
                  <a class="link subtitle--one" data-test="menu-parents-tips">
                    <span class="link__text">{{
                      'Common.Menu.Parents.tips' | translate
                    }}</span>
                  </a>
                  <a class="link subtitle--one" data-test="menu-parents-files">
                    <span class="link__text">{{
                      'Common.Menu.Parents.files' | translate
                    }}</span>
                  </a>
                </div>
                <div class="is-mobile">
                  <ng-select
                    placeholder="{{
                      'Common.Menu.Students.title_col1' | translate
                    }}"
                    [searchable]="false"
                    [clearable]="false"
                    class="ng-select--clean"
                  >
                    <ng-option value="1">
                      <a data-test="menu-parents-articles">{{
                        'Common.Menu.Parents.articles' | translate
                      }}</a>
                    </ng-option>
                    <ng-option value="2">
                      <a data-test="menu-parents-chronicles">
                        {{ 'Common.Menu.Parents.chronicles' | translate }}
                      </a>
                    </ng-option>
                    <ng-option value="3">
                      <a data-test="menu-parents-tools">{{
                        'Common.Menu.Parents.tools' | translate
                      }}</a>
                    </ng-option>
                    <ng-option value="4">
                      <a data-test="menu-parents-tips">{{
                        'Common.Menu.Parents.tips' | translate
                      }}</a></ng-option
                    >
                    <ng-option value="5">
                      <a data-test="menu-parents-files">{{
                        'Common.Menu.Parents.files' | translate
                      }}</a></ng-option
                    >
                  </ng-select>
                </div>
              </div>
              <div>
                <div class="grid--parents__info is-desktop links__body1">
                  <div class="title--h4">
                    {{ 'Common.Menu.Parents.talk_to_professional' | translate }}
                  </div>
                  <ul>
                    <li>
                      <a
                        data-test="menu-parents-phone"
                        class="link"
                        href="tel://18555271277"
                      >
                        <span class="icon icon-phone"></span
                        ><span class="link__text">1-855-527-1277</span>
                      </a>
                    </li>
                    <li>
                      <a
                        data-test="menu-parents-messenger"
                        [href]="tools.getConfig('links').messenger_parents"
                        class="link"
                        target="_blank"
                      >
                        <span class="icon icon-reading"></span
                        ><span class="link__text">{{
                          'Common.Menu.Parents.talk_messenger' | translate
                        }}</span>
                      </a>
                    </li>
                    <li>
                      <a
                        class="link"
                        data-test="menu-parents-email"
                        [href]="
                          'mailto:' + tools.getConfig('links').email_parents
                        "
                      >
                        <span class="icon icon-mail"></span
                        ><span class="link__text">{{
                          'Common.Menu.Parents.talk_email' | translate
                        }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="is-mobile grid--parents__info--mobile">
                  <ng-select
                    placeholder="{{
                      'Common.Menu.Parents.talk_to_professional' | translate
                    }}"
                    class="ng-select--clean"
                    [searchable]="false"
                    [clearable]="false"
                  >
                    <ng-option value="1"
                      ><a
                        data-test="menu-parents-phone"
                        href="tel://18555271277"
                        ><span class="icon icon-phone"></span>1-855-527-1277</a
                      ></ng-option
                    >
                    <ng-option value="2"
                      ><a
                        data-test="menu-parents-messenger"
                        [href]="tools.getConfig('links').messenger_parents"
                        target="_blank"
                        ><span class="icon icon-reading"></span>
                        {{
                          'Common.Menu.Parents.talk_messenger' | translate
                        }}</a
                      ></ng-option
                    >
                    <ng-option value="3"
                      ><a
                        data-test="menu-parents-email"
                        [href]="
                          'mailto:' + tools.getConfig('links').email_parents
                        "
                        ><span class="icon icon-mail"></span>
                        {{ 'Common.Menu.Parents.talk_email' | translate }}</a
                      ></ng-option
                    >
                  </ng-select>
                </div>
              </div>
              <div>
                <div class="title--h4">
                  {{
                    'Common.Menu.Parents.newsletter_subscription' | translate
                  }}
                </div>
                <app-newsletter></app-newsletter>
                <div class="buttom__actions">
                  <a
                    data-test="menu-parents-social-fb"
                    [href]="tools.getConfig('links').facebook"
                    target="_blank"
                  >
                    <span class="icon icon-social-facebook icon--shrink"></span>
                  </a>
                  <a
                    data-test="menu-parents-social-instagram"
                    [href]="tools.getConfig('links').instagram"
                    target="_blank"
                  >
                    <span
                      class="icon icon-social-instagram icon--shrink"
                    ></span>
                  </a>
                  <a
                    data-test="menu-parents-social-yt"
                    [href]="tools.getConfig('links').youtube"
                    target="_blank"
                  >
                    <span class="icon icon-social-youtube icon--shrink"></span>
                  </a>
                  <a
                    data-test="menu-students-social-twitter"
                    [href]="tools.getConfig('links').twitter"
                    target="_blank"
                  >
                    <span class="icon icon-social-twitter icon--shrink"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <aside class="nav__link nav__link--parents" data-theme="default">
        <a class="title--h4" (click)="switchMenu()"
          ><span>{{
            'Common.Menu.Parents.switch_section' | translate
          }}</span></a
        >
      </aside>
    </app-base-section>
  </div>
</div>
