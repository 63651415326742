import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-reset-btn',
  templateUrl: './reset-btn.component.html',
  styleUrls: ['./reset-btn.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ResetBtnComponent {
  @Input() className: string;
  constructor() { }
}
