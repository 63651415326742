const getAlertFullGQL = `
{
  alert(lang: "$lang") {
    uuid
    title
    status
    color
    body
    uri
    uri_title
  }
}
`;

  export {
    getAlertFullGQL
  };
