import { Injectable, Inject, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '@env/environment';
import { config } from '@env/all';
import { Topic, TopicVal, FileType, FileTypeVal } from '@app/shared/enums';
import { ContentType } from '@app/models/models';

@Injectable({
  providedIn: 'root',
})
export class ToolsService {
  public renderer: Renderer2;
  public storage = window.localStorage;
  public state = 'students';
  public isYouTubeIframeAPIReady = false;
  public html = this.document.documentElement;
  public body = this.document.body;
  public restoreScrollY = 0;
  public fileModuleScrollOffset = 150;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public getEnumKeys(enumVal: string) {
    switch (enumVal) {
      case 'topics':
        return Object.keys(Topic);
      case 'fileTypes':
        return Object.keys(FileType);
      default:
        throw new Error('Unsupported enum: ' + enumVal);
    }
  }

  public getTopicVal(topic: string) {
    return TopicVal[topic].toString();
  }

  public getTypeVal(type: string) {
    return FileTypeVal[type].toString();
  }

  // Enable/disable Password Management in My Account and Forgot Password across the project
  public managePassword() {
    return false;
  }

  public isReducedMotion() {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    return mediaQuery.matches;
    // TODO: We might need a listener
    // mediaQuery.addEventListener('change', () => mediaQuery.matches);
  }

  public rgbToHex(rgb) {
    // Choose correct separator
    let sep = rgb.indexOf(',') > -1 ? ',' : ' ';
    // Turn "rgb(r,g,b)" into [r,g,b]
    rgb = rgb
      .substr(4)
      .split(')')[0]
      .split(sep);

    let r = (+rgb[0]).toString(16),
      g = (+rgb[1]).toString(16),
      b = (+rgb[2]).toString(16);

    if (r.length == 1) r = '0' + r;
    if (g.length == 1) g = '0' + g;
    if (b.length == 1) b = '0' + b;

    return '#' + r + g + b;
  }

  public getContentInfo(url: string): ContentType {
    if (url.startsWith('/bv/')) {
      const urlParts = url.split('-');
      if (urlParts.length > 1) {
        return {
          content_type: 'file',
          content_id: urlParts[urlParts.length - 1],
        };
      }
    }
    return {
      content_type: 'page',
      content_id: url,
    };
  }

  public getConfig(key: string): any {
    return !!environment[key] ? environment[key] : config[key];
  }

  setLangAttr(lang: string): void {
    this.document.documentElement.lang = lang;
  }

  addBodyClass(cls: string): void {
    this.addClass(this.body, cls);
  }
  removeBodyClass(cls: string): void {
    this.removeClass(this.body, cls);
  }
  addClass(el: any, cls: string): void {
    this.renderer.addClass(el, cls);
  }
  removeClass(el: any, cls: string): void {
    this.renderer.removeClass(el, cls);
  }
  toggleClass(el: any, cls: string): void {
    if (el.classList.contains(cls)) {
      this.removeClass(el, cls);
    } else {
      this.addClass(el, cls);
    }
  }
  isInView(el: HTMLElement, threshold: number) {
    if (!el) return false;

    const { top } = el.getBoundingClientRect();
    const topThreshold = this.distanceFromTopOfViewport(threshold);

    return top <= topThreshold;
  }
  distanceFromTopOfViewport(ratio: number) {
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;

    return windowHeight * ratio;
  }

  getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  getRandomFloat(min: number, max: number, scale = 7): number {
    return this.round((Math.random() * (max - min)) + min, scale);
  }

  clamp(min: number, max: number, value: number): number {
    return Math.min(max, Math.max(min, value));
  }

  round(val: number, scale = 7): number {
    const decimals = Math.pow(10, scale);
    return Math.round((val + Number.EPSILON) * decimals) / decimals;
  }

  setBodyAttribute(attr: string, val: string): void {
    this.setAttribute(this.body, attr, val);
  }
  setAttribute(el: any, attr: string, val: string): void {
    this.renderer.setAttribute(el, attr, val);
  }

  setTheme(themeKey: string): void {
    //FIXME remove if Other topic is enable
    const theme = themeKey === Topic.others ? 'default' : themeKey;
    this.setBodyAttribute('data-theme', theme);
  }
  setPortal(portalKey: string): void {
    this.setBodyAttribute('data-portal', portalKey);
  }

  scrollIntoView(el: Element | string, block = 'start') {
    if (typeof el === 'string') {
      el = this.document.querySelector(el);
    }
    if (el) {
      el.scrollIntoView({
        behavior: 'smooth',
        block: block as ScrollLogicalPosition,
      });
    }
  }

  scrollTo(options?: ScrollToOptions) {
    window.scrollTo(Object.assign({
      left: 0,
      top: 0,
      behavior: 'smooth',
    }, options));
  }

  scrollBy(options?: ScrollToOptions) {
    window.scrollBy(Object.assign({
      left: 0,
      top: 0,
      behavior: 'smooth',
    }, options));
  }

  shuffleArray(a) {
    let j, x, i;

    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }

    return a;
  }

  createWatcher(options, callback) {
    const defaults = {
      root: null,
      rootMargin: '0px',
      threshold: 0
    };

    const mergedOptions = { ...defaults, ...options };

    const watcher = new IntersectionObserver(callback, mergedOptions);

    return watcher;
  }

  bpIsLowerThan(width: number) {
    return this.body.offsetWidth < width;
  }
  bpIsLowerOrEqualThan(width: number) {
    return this.body.offsetWidth <= width;
  }

  preventSiteScroll() {
    this.restoreScrollY = window.scrollY;
    this.addBodyClass('prevent-scroll');
    this.addClass(this.html, 'prevent-scroll');
  }

  allowSiteScroll() {
    this.removeBodyClass('prevent-scroll');
    this.removeClass(this.html, 'prevent-scroll');
    this.scrollTo({
      left: 0,
      top: this.restoreScrollY,
      behavior: 'auto'
    });
    this.restoreScrollY = 0;
  }

  isMobile() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }
}
