import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Tile } from '@app/models/content';
import {TileContext, TileFormat} from "@app/shared/enums";

@Component({
  selector: 'app-tile-revision',
  templateUrl: './tile-revision.component.html',
  styleUrls: ['./tile-revision.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TileRevisionComponent  {

  @Input() tile: Tile;
  @Input() format: TileFormat;
  @Input() context: TileContext;

  constructor() { }
}
