export const config = {
  links: {
    cyberclasse: 'https://sp.alloprof.qc.ca/Pages/A-propos/Mission/Les-services-All%c3%b4-prof.aspx',
    //cyberclasse: 'https://cyberclasse.alloprof.qc.ca',
    forums: 'https://sp.alloprof.qc.ca/forums',
    //forums: 'https://sp.alloprof.qc.ca/forums',
    zoneTeacher: 'http://blogue.alloprof.qc.ca/categorie/zone-enseignants',
    facebook: 'https://www.facebook.com/alloprof',
    instagram: 'https://www.instagram.com/alloprof',
    youtube: 'https://www.youtube.com/user/BV2ALLOPROF',
    //twitter: 'https://twitter.com/alloprof',
    messenger_parents: 'https://www.messenger.com/t/1423194451050574',
    email_parents: 'info@alloprofparents.ca',
    partner_quebec: 'http://www.mels.gouv.qc.ca',
    partner_riotinto: 'https://www.riotinto.com/canada-10512-fr.aspx',
    partner_desjardins:
      'https://www.desjardins.com/a-propos/responsabilite-sociale-cooperation/education-cooperation/fondation-desjardins',
    partner_troittier: 'http://about.telus.com/community/french/news_centre/company_overview/community_involvement',
    partner_other: 'http://www.alloprof.qc.ca/Pages/A-propos/Nos-partenaires/Nos-partenaires.aspx',
    // footer
    donate: 'https://www.canadahelps.org/fr/dn/3322',
    about: 'https://sp.alloprof.qc.ca/Pages/A-propos.aspx',
    team: 'https://sp.alloprof.qc.ca/Pages/A-propos/Qui-oeuvre-a-allo-prof/Travailler-a-Allo-prof.aspx',
    contact: 'https://docs.google.com/forms/d/e/1FAIpQLSdZ0CCG8v35PQhgDKKrwiux-oYRARr4-DOVtEF_azIsEMvuLA/viewform?usp=sf_link',
    blog: 'http://blogue.alloprof.qc.ca',
    contest: 'https://alloprof.qc.ca/Pages/A-propos/R%C3%A8glements-des-concours.aspx',
    partners: 'https://sp.alloprof.qc.ca/Pages/A-propos/Nos-partenaires/Nos-partenaires.aspx',
    medias: 'http://blogue.alloprof.qc.ca/categorie/salle-de-presse',
    policy: 'https://sp.alloprof.qc.ca/Pages/A-propos/Conditions-d%27utilisations-et-Politique-de-confidentialit%C3%A9.aspx',
    mission: 'https://sp.alloprof.qc.ca/Pages/A-propos/Mission/Mission.aspx',
    promo: 'https://docs.google.com/forms/d/e/1FAIpQLSfyb_FFvjcJHqEb03tkquwpN4x4ppwHOtJnGWQrAFsFHK7_8g/viewform'
  }
};
