import { Component, OnInit, OnDestroy } from '@angular/core';
import { EventsService } from '@app/services/services';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  constructor(public events: EventsService) { }

  loading = false;

  ngOnInit() {
    this.events.on('loading', this.setLoading);
  }
  ngOnDestroy() {
    this.events.off('loading', this.setLoading);
  }

  setLoading = (state) => {
    this.loading = state;
  }
}
