import {
    trigger,
    style,
    animate,
    transition,
    query,
    group,
    sequence,
    stagger,
    animateChild
  } from '@angular/animations';

const transitionParamsLeave = '220ms cubic-bezier(.55,.07,.76,.5)';
const transitionParamsEnter = '400ms 250ms cubic-bezier(.29,.46,.15,1.01)';

const bigTransitionParamsEnter = '1000ms cubic-bezier(.2,.2,0,1)';
const bigTransitionStaggerDelayIn = 10;
const bigTransitionStaggerDelayOut = -10;
const bigTransitionStaggerEaseIn = '700ms cubic-bezier(.29,.46,.15,1.01)';
const bigTransitionStaggerEaseOut = '700ms cubic-bezier(.57,.07,.45,.88)';
const bigTransitionStaggerTransformIn = 'translateY(-120vh) rotate(90deg)';
const bigTransitionStaggerTransformNormal = 'translateY(0) rotate(0deg)';
const bigTransitionStaggerTransformOut = 'translateY(150vh) rotate(-90deg)';

export const routeAnimations = trigger('routeAnimations', [
  /**
   * Sticker Bomb transition animation (e.g. covering the viewport with SVG stickers)
   */
  transition('* => LibraryTopic', [

    query(':leave', [
      style({
        position: 'absolute',
        width: '100%'
      })
    ], { optional: true }),
    query(':enter', [
      style({
        position: 'fixed',
        width: '100%',
        opacity: 0,
        transform: 'translateY(2rem)'
      }),
    ], { optional: true }),
    sequence([
      query('.app__transitions', [
        style({
          display: 'block',
          position: 'fixed',
          top: 0,
          width: '100%'
        }),
      ], { optional: true }),

      group([
        query('.app__transition-bg', [
          style({position: 'absolute', opacity: 0}),
          animate(bigTransitionStaggerEaseIn, style({position: 'absolute', opacity: 1})),
        ], { optional: true }),
        query('.app__transition-item', [
          style({position: 'absolute', transform: bigTransitionStaggerTransformIn}),
          stagger(bigTransitionStaggerDelayIn, [
            animate(bigTransitionStaggerEaseIn, style({ transform: bigTransitionStaggerTransformNormal }))
          ])
        ], { optional: true }),

        query(':leave', [
          style({
            position: 'absolute',
          }),
          animate(bigTransitionParamsEnter, style({
            opacity: 0,
          })),
          animateChild()
        ], { optional: true }),
      ]),

      group([
        query(':enter', [
          style({
            display: 'block',
            width: '100%'
          }),
          animate(bigTransitionParamsEnter, style({
            opacity: 1,
            transform: 'translateY(0)'
          })),
          animateChild()
        ], { optional: true }),

        query('.app__transition-bg', [
          style({position: 'absolute', opacity: 1}),
          animate(bigTransitionStaggerEaseOut, style({position: 'absolute', opacity: 0})),
        ], { optional: true }),
        query('.app__transition-item', [
          stagger(bigTransitionStaggerDelayOut, [
            animate(bigTransitionStaggerEaseOut, style({ transform: bigTransitionStaggerTransformOut }))
          ])
        ], { optional: true }),
      ]),

      query('.app__transitions', [
        style({
          top: '150%',
          position: 'fixed'
        }),
      ], { optional: true }),

    ])
  ]),


  /**
   * Vertical Fade, default
   */
  transition('* => *', [
    group([
      query(':enter, :leave', [
        style({
          opacity: 0,
        }),
      ], { optional: true }),

      query(':leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          opacity: 1,
          transform: 'translateY(0)'
        }),
        animate(transitionParamsLeave, style({
          opacity: 0,
          transform: 'translateY(-2rem)'
        })),
        animateChild()
      ], { optional: true }),

      query(':enter', [
        style({
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          opacity: 0,
          transform: 'translateY(2rem)'
        }),
        animate(transitionParamsEnter, style({
          opacity: 1,
          transform: 'translateY(0)'
        })),
        animateChild()
      ], { optional: true }),
    ])
  ])
]);

export const toggleAnimation = trigger('toggleAnim', [
  transition(':enter', [
    style({
      position: 'absolute',
      width: '100%',
      opacity: 0,
      transform: 'translate3d(0, 2rem, 0)'
    }),
    animate('500ms cubic-bezier(.25,0,.4,1)', style({
      position: 'relative',
      opacity: 1,
      transform: 'translate3d(0, 0, 0)'
    })),
  ]),
  transition(':leave', [
    style({
      position: 'absolute',
      width: '100%',
      opacity: 1,
      transform: 'translateY(0rem)'
    }),
    animate('500ms cubic-bezier(.25,0,.4,1)', style({
      position: 'relative',
      opacity: 0,
      transform: 'translate3d(0, -2rem, 0)'
    }))
  ])
]);
