<div
  class="section"
  [id]="id || null"
  [ngClass]="sectionClass || null"
  [attr.data-bg-color]="bgColor || null"
  [attr.data-is-wrapping]="isWrapping || null"
  [attr.data-has-slider]="hasSlider"
  [attr.data-section-is-narrow]="isNarrow"
  [attr.data-section-is-first]="isFirst"
  [attr.data-section-is-last]="isLast"
  [attr.data-section-no-left]="noLeft || null"
  [attr.data-section-no-right]="noRight || null"
>
  <div class="section__content">
    <ng-content></ng-content>
  </div>
</div>
