<div
  *ngIf="tile"
  class="tile-module"
  [attr.data-tile-format]="format"
  [attr.data-tile-type]="tile.type"
  [attr.data-tile-subtype]="tile.subtype || null"
  [attr.data-tile-featured]="tile.featured ? 'true' : 'false'"
  [attr.data-tile-context]="context || null"
>
  <div class="tile__inner">

    <div class="tile__top">
      <div class="tile__top-img-container" *ngIf="tile.image">
        <img [src]="tile.image.url" [alt]="tile.image.alt" class="tile__image">
      </div>
      <svg class="tile__sticker">
        <use xlink:href="#sticker--play-tile" />
      </svg>
    </div>

    <div class="tile__middle">
      <h4
        class="tile__title"
        [class.title--h3]="format === 'medium'"
        [class.subtitle--tri]="format === 'xsmall'"
        mathjax
      >{{ tile.title }}</h4>
      <div class="tile__infos caption" *ngIf="tile.thematic || tile.levels">
        <span class="tile__thematic" *ngIf="tile.thematic">
           {{ 'Parents.Thematics.'+tile.thematic+'.label' | translate }}
        </span>
        <span class="tile__level" *ngFor="let level of tile.levels">
          <span class="tile__level-years" *ngIf="level.years">{{ level.years }}</span>
        </span>
        <span class="tile__parent-file" *ngIf="tile.parentTitle">
          {{ tile.parentTitle }}
        </span>
      </div>
      <button class="tile__button btn btn--sm" *ngIf="format === 'large'">
        {{'Common.Actions.view_video'|translate}}
      </button>
    </div>
    <div class="tile__bottom">
      <button class="link link--sm link--active">
        <span class="link__text">{{'Common.Actions.view_video'|translate}}</span>
      </button>
    </div>

    <a
      [href]="tile.url"
      class="tile__link"
      [attr.rel]="tile.openExternal ? 'noreferrer noopener' : null"
      [attr.target]="tile.openExternal ? '_blank' : null"
    >
      <span class="tile__arrow icon icon-arrow"></span>
    </a>

  </div>
</div>
