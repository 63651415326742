import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent  {
  @Input() classModifier = '';
  @Input() isThemed = false;
  @Input() theme = '';
  @Input() test = '';
  @Input() link = '';
  @Input() label = '';

  constructor() { }
}
