import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  AuthService,
  EventsService,
  ToolsService,
} from '@app/services/services';
import { Router, NavigationStart } from '@angular/router';
import { User } from '@app/models/models';
import { Breakpoints } from '@app/shared/enums';
import { Subscription } from 'rxjs';
import { environment } from '@env/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() isParentPage = false;
  user: User;
  isMobile = this.tools.bpIsLowerOrEqualThan(Breakpoints.small);
  otherLang: string;
  otherLangUrl: string;
  logoUrl = 'assets/images/logo.svg';
  subscriptions: Subscription[] = [];
  loading: boolean;
  active = false;
  isProduction = environment.production;

  constructor(
    private events: EventsService,
    private router: Router,
    private auth: AuthService,
    public translate: TranslateService,
    public tools: ToolsService
  ) {
    this.loading = true;
    this.subscriptions.push(
      this.auth.getCurrentUser().subscribe(user => {
        this.user = user;
        this.userUpdated();
        this.loading = false;
      })
    );
    this.subscriptions.push(
      this.translate.onLangChange.subscribe((lang: string) => {
        this.setLang();
      })
    );

    window.addEventListener('resize', this.updateIsMobile);
  }

  ngOnInit() {
    this.userUpdated();
    this.subscriptions.push(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          this.events.broadcast('header:close');
          if (this.active) {
            this.toggleUserMenu();
          }
        }
      })
    );
  }

  userUpdated() {
    this.events.on('user:updated', this.updateAvatar);
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.updateIsMobile);
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  updateAvatar = user => {
    this.user = user;
  }

  updateIsMobile = () => {
    this.isMobile = this.tools.bpIsLowerOrEqualThan(Breakpoints.small);
  }

  setLang() {
    this.otherLang = this.translate.currentLang === 'en' ? 'fr' : 'en';
    this.otherLangUrl =
      '/' + (this.otherLang === 'en' ? 'en' : '') + '/parents';
  }

  openMenu() {
    this.events.broadcast('header:open');
  }

  openSearch() {
    this.events.broadcast('search:open');
  }

  logout() {
    this.active = false;
    this.auth.logout().then(() => this.events.broadcast('auth:logout'));
  }

  toggleUserMenu() {
    this.active = !this.active;
  }
}
