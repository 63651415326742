import { Injectable } from '@angular/core';
import { TableOverflow } from './TableOverflow';

@Injectable({
  providedIn: 'root'
})
export class TableOverflowManager {

  tables: TableOverflow[] = [];

  checkOverflowTables() {
    // need to wait a little bit for dom to be completely changed
    setTimeout(() => {
      const tables = document.querySelectorAll('.table-wrapper');
      tables.forEach((table, i) => this.checkTable(table, i));
    }, 500);
  }

  checkTable(table, id) {
    const parent = table;
    const child = table.querySelector('table');

    const parentSize = parent.offsetWidth;
    const childSize = child.clientWidth;

    // if the child is bigger then it needs a scroll check to displays the borders
    if (childSize > parentSize) {

      // create an id for the overflow table
      const idTable = 'table-' + id;

      // check if table is Already registered, to avoid duplication of TableOverflow created
      const isTableCreated = this.tables.findIndex(t => t.id === idTable);

      //  if table is not registered we create it
      if (isTableCreated === -1) {
        this.tables.push(new TableOverflow(parent, idTable));
      }
    }
  }

  clearTables() {
    // clear all current tables with overflow
    this.tables.forEach(table => table.clearTable());
    this.tables.length = 0;
  }
}
