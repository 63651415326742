import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ApiService } from '@app/services/api/api.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent  {
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  submitted = false;
  unsuccessful = false;

  constructor(
    private apiService: ApiService
  ) {}

  submitForm(email: string) {
    this.submitted = true;
    this.unsuccessful = false;

    console.log(email)

    this.apiService.post('URL TO SEND', email );
  }
}
