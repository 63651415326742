import {Injectable, OnDestroy} from '@angular/core';
import {Meta, MetaDefinition} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {MetaTag} from '@app/models/global';
import {Title} from '@angular/platform-browser';
import metatagsFR from '@app/metatags-fr.json';
import metatagsEN from '@app/metatags-en.json';
import {Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MetatagsService implements OnDestroy {

  private frontendMetaTags = {
    fr: metatagsFR,
    en: metatagsEN
  };
  private currentRoute = null;
  private baseTagsAdded = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private translate: TranslateService,
    private meta: Meta,
    private titleService: Title
  ) {
    this.subscriptions.push(
      this.translate.onLangChange.subscribe(() => {
        this.baseTagsAdded ? this.updateBaseTags() : this.addBaseTags();
        this.updateFrontendTags();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private addBaseTags() {
    this.frontendMetaTags[this.translate.currentLang].base.forEach(meta => {
      this.meta.addTag(meta);
    });

    this.meta.addTag({
      property: 'og:url',
      content: window.location.origin + window.location.pathname
    });

    this.baseTagsAdded = true;
  }

  private updateBaseTags() {
    this.frontendMetaTags[this.translate.currentLang].base.forEach(meta => {
      this.meta.updateTag(meta);
    });

    this.updateUrlTag();
  }

  private updateUrlTag() {
    this.meta.updateTag({
      property: 'og:url',
      content: window.location.origin + window.location.pathname
    });
  }

  private extractRoute(route: string) {
    this.currentRoute = new URL(`https://alloprof.qc.ca${route}`).pathname;
    this.updateFrontendTags();
  }

  private updateFrontendTags() {
    if (this.currentRoute && this.frontendMetaTags[this.translate.currentLang][this.currentRoute]) {
      this.frontendMetaTags[this.translate.currentLang][this.currentRoute].forEach(meta => {
        const mks = Object.keys(meta);
        if (meta.name && meta.name === 'title') {
          this.titleService.setTitle(meta.content);
        }
        this.meta.getTag(`${mks[0]} = "${meta[mks[0]]}"`) ?
          this.meta.updateTag(meta) :
          this.meta.addTag(meta);
      });
    }

    this.updateUrlTag();
  }

  private updateCMSTags(metatags: MetaTag[]) {
    metatags.forEach(meta => {
      if (meta.tag === 'meta') {
        const cleanMeta = {} as MetaDefinition;
        if (meta.attributes.name) {
          cleanMeta.name = meta.attributes.name;
          if (meta.attributes.name === 'title') {
            this.titleService.setTitle(meta.attributes.content);
          }
        } else {
          cleanMeta.property = meta.attributes.property;
        }
        cleanMeta.content = meta.attributes.content;
        this.meta.updateTag(cleanMeta);
      }
    });

    this.updateUrlTag();
  }

  public updateMetaTags(routeOrMetatags: MetaTag[] | string = null) {
    typeof routeOrMetatags === 'string' ?
      this.extractRoute(routeOrMetatags) :
      this.updateCMSTags(routeOrMetatags);
  }
}
