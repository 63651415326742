import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  private asyncStateData = new BehaviorSubject('');
  public asyncData = this.asyncStateData.asObservable();
  public data: any;

  constructor() { }

  setAsync(data: any) {
    this.asyncStateData.next(data);
  }

  deleteAsync() {
    this.setAsync(null);
  }

  set(data: any) {
    this.data = data;
  }

  delete() {
    delete this.data;
  }
}
