import {Component, Input, OnInit} from '@angular/core';
import { Tile } from '@app/models/content';
import {TileContext, TileFormat} from '@app/shared/enums';
import {EventsService} from '@app/services/events/events.service';

@Component({
  selector: 'app-tile-video',
  templateUrl: './tile-video.component.html',
  styleUrls: ['./tile-video.component.scss']
})
export class TileVideoComponent implements OnInit {

  @Input() tile: Tile;
  @Input() format: TileFormat;
  @Input() context: TileContext;

  constructor(
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
    if (!this.tile.image && this.tile.topic) {
      this.tile.image = {
        url: `assets/images/tiles/video/${this.tile.topic}.svg`
      };
    }
  }

  onClick() {
    this.eventsService.broadcast('app:popstate:restore-scroll');
  }
}
