import { Injectable } from '@angular/core';
import { ToastrService, IndividualConfig } from 'ngx-toastr';
import { AuthService } from '../auth/auth.service';
import { ContentService } from '../content/content.service';
import { User } from '@app/models/models';
import { Notification } from '@app/models/content';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  notificationOptions: Partial<IndividualConfig> = {
    closeButton: true,
    disableTimeOut: true,
    tapToDismiss: false,
    enableHtml: true
  }
  notifications: Notification[] = [];
  user: User;

  constructor(
    private translate: TranslateService,
    private toastr: ToastrService,
    private content: ContentService,
    private auth: AuthService,
  ) {
    this.auth.getCurrentUser().subscribe(user => {
      this.user = user;
      this.checkForNotifications();
    })
  }

  async checkForNotifications() {
    const userLogged = this.user !== null;
    const apiNotifs = await this.content.getApiNotifications(this.translate.currentLang).toPromise();

    apiNotifs.data.alert.forEach(apiNotif => this.content.setLocalStorageNotificationsIfNotExist(apiNotif, userLogged));
    const localStorageNotifs: Notification[] = await this.content.getLocalStorageNotifications();
    localStorageNotifs.forEach(localNotif => {
      if (!localNotif.closed) this.notifications.push(localNotif);
    });

    this.displayNotifications();
  }

  displayNotifications() {
    this.notifications.forEach(notif => {
      const { body, title, color } = notif;
      const type = (['rouge', 'red'].indexOf(color) > -1 ? 'error' : 'success');
      let formatedBody = body.replace(/(<([^>]+)>)/ig, '');
      const toast = this.toastr[type](formatedBody, title, this.notificationOptions);
      toast.onHidden.subscribe(() => this.content.setLocalStorageNotificationsClosed(notif));
    });
  }
}
