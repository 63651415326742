export const mouthExpressions = {
  bigSmile: 'M89.94,29.52c23-3.42,47.9,10,85.65,10.18,27.29.13,78.07-18.66,93.76-14,9.51,2.81,18.47,5.12,19.28,26.89,1.21,32.38-17.89,47.58-38.26,59.07-19.53,11-36.7,15.52-74.8,16-33.5.45-58.91-7.57-74.66-16.68C78,97.71,62,82.89,62.51,59.33,62.79,46,66.9,32.93,89.94,29.52Z',
  crying: 'M259.22,138.05c-22.66,3-46.88-10.54-83.93-11.27-26.8-.53-76.92,17.16-92.26,12.37-9.29-2.9-18-5.3-18.52-26.68C63.81,80.66,82.78,66,103,55.05c19.33-10.52,36.25-14.69,73.66-14.63,32.9.06,57.72,8.31,73,17.49,22.33,13.37,37.77,28.17,36.93,51.28C286.12,122.28,281.88,135,259.22,138.05Z',
  line: 'M139.09,50.47h73v9h-73v-9Z',
  narrowSmile: 'M210.34,89.77c-8.53.18-33.38.52-38.24.33-27.36-1.07-45.66-2.76-66.64-5.23-2.79-.33-3.87-3.41-3.45-6.18s3.63-4.21,6.41-3.84c18.35,2.39,47.93,5,63.93,5.09s20.33.42,42.51-.55c22.71-1,25.19-2,35.21-3.26,2.83-.34,6.21.24,6.77,4.1.59,4.06-1.91,4.72-4.47,5.85C252.37,86.08,224.8,89.45,210.34,89.77Z',
  neutralSmile: 'M185.82,90.28q-7.14,0-14.43-.18c-52.66-1.29-105.56-9.78-138-22.17A5.08,5.08,0,1,1,37,58.44c31.48,12,83.09,20.24,134.68,21.5,30.66.76,59.26-.9,84.47-4.78,23.37-3.59,43.83-9.09,60.32-16.34a5.08,5.08,0,0,1,4.09,9.3c-32.76,14.4-80.19,22.16-134.7,22.1',
  oFace: 'M166.21,71.51a14.79,14.79,0,0,1,9.36-3.77,12.68,12.68,0,0,1,10.6,4.72A15,15,0,0,1,189.59,80a15.17,15.17,0,0,1-3.33,11,14.16,14.16,0,0,1-10.69,4.8,13.37,13.37,0,0,1-9.82-4.16c-3.22-3.28-4.37-6.71-4.31-9.85A15,15,0,0,1,166.21,71.51Z',
  rounded: 'M198.21,105.32H153a23.5,23.5,0,0,1,0-47h45.24a23.5,23.5,0,1,1,0,47',
  roundedWide: 'M203.21,105.32H108a23.5,23.5,0,0,1,0-47H243.21a23.5,23.5,0,0,1,0,47',
  smirk: 'M225.28,82.75c-15.43,3.19-37.89,4.24-50.48,4.87-15.65.8-43.79.4-75.74-5.12-2.77-.48-3.94-3.94-2.94-6.56s3.78-3.31,6.56-2.94c25.73,3.43,35.95,4.83,72.8,4.69,15.58-.06,51.76-4.42,74.17-10.7,27.1-7.59,37.77-12.66,56-25.79,2.05-1.47,4.83-3,7.45-.12,2.15,2.35,1.73,5.73-1.07,7.89C283.76,70.8,249.64,77.72,225.28,82.75Z',
  square: 'M139.09,50.47h73v73h-73v-73Z',
  straight: 'M281.07,90.28H72.55a5.06,5.06,0,1,1,0-10.12H281.07a5.06,5.06,0,1,1,0,10.12'
};

export const facePresets = {
  default: {
    bodyRotation: 0,
    eyebrowDistance: 5,
    eyebrowExpressionR: 'curved',
    eyebrowExpressionL: 'curved',
    eyebrowRotationR: -13,
    eyebrowRotationL: 13,
    eyeClosedType: 'curvedDown',
    eyelidR: 1,
    eyelidL: 1,
    faceScale: 1,
    facePositionX: 0,
    facePositionY: 0,
    finRotate: -7,
    mouthExpression: mouthExpressions.neutralSmile,
    mouthScale: 1,
    retinaScale: 1,
    retinasPositionX: 0,
    retinasPositionY: 0,
    upperEyelidL: 1.4,
    upperEyelidR: 1.4,
    eyebrowScale: 1,
    cEyeScale: 0
  },
  minimized: {
    bodyRotation: 0,
    eyelidR: 0,
    eyelidL: 0,
    facePositionX: 9,
    facePositionY: 130,
    faceScale: 1.7,
    finRotate: -7,
    mouthExpression: mouthExpressions.neutralSmile,
    retinasPositionX: 0,
    retinasPositionY: 0,
    mouthScale: 0,
    eyebrowScale: 0,
    cEyeScale: 1,
    shakingAngle: 15
  },
  awake: {
    bodyRotation: 0,
    eyebrowExpressionR: 'curved',
    eyebrowExpressionL: 'curved',
    facePositionY: 80,
    eyebrowRotationR: -13,
    eyebrowRotationL: 13,
    eyebrowDistance: 5,
    eyelidR: 1,
    eyelidL: 1,
    upperEyelidL: 1,
    upperEyelidR: 1,
    finRotate: -2
  },
  enterSearch: {
    eyebrowDistance: 20,
    mouthExpression: mouthExpressions.bigSmile,
    upperEyelidL: 1,
    upperEyelidR: 1,
  }


};

export const bodyPresets = {
  default: {
    bicepR:  -180,
    bicepL: -180,
    forearmR: 0 ,
    forearmL: 0,
    handR: 0,
    handL: 0,
    handTypeL: 'palm',
    handTypeR: 'palm',
    handScaleL: -0.95,
    handScaleR:  -0.95,
    handLPos :  0,
    handRPos :  0,
  },
  minimized: {
    bicepL:  -220, // 200
    bicepR: -220,
    forearmR: -44,
    forearmL: -44,
    handR: 0,
    handL: 0,
    handTypeL: 'palm',
    handTypeR: 'palm',
    handScaleL: -0.95,
    handScaleR:  -0.95,
    handLPos :  0,
    handRPos :  0,
  },
  validatation : {
    handTypeR: 'finger',
  }
};

export const disabledRoutes = [
  '404',
  'auth',
  'profile'
];
