import { NgModule } from '@angular/core';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MathJaxModule } from 'ngx-mathjax';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [ AppComponent ],
  imports: [
    CoreModule,
    AppRoutingModule,
    MathJaxModule.forRoot({
      version: '2.7.6',
      config: 'TeX-MML-AM_CHTML',
      hostname: 'cdnjs.cloudflare.com'
    }),
    TranslateModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      maxOpened: 3
    })
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule {
}
