import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Tile } from '@app/models/content';
import { TileContext, TileFormat } from '@app/shared/enums';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TileComponent implements OnInit {

  @Input() tile: Tile;
  @Input() format: TileFormat;
  @Input() context: TileContext;

  realType: string;

  constructor() { }

  ngOnInit(): void {
    this.realType = this.tile.subtype || this.tile.type;
  }
}
