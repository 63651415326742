import { environment } from '@env/environment';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SharedModule } from '@app/shared/shared.module';
import { HeaderComponent } from './components/header/header.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SiteSearchComponent } from './components/site-search/site-search.component';
import { EscapeHtmlPipe } from '@app/shared/pipes/keep-html.pipe';

@NgModule({
  declarations: [
    HeaderComponent,
    LoaderComponent,
    SiteSearchComponent,
    EscapeHtmlPipe
 ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    ScrollingModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularFirestoreModule,
    ScrollingModule,
    SharedModule,
    TranslateModule,
    HeaderComponent,
    LoaderComponent,
    SiteSearchComponent
  ],
  providers: [
    TranslateService,
    AngularFireAuth,
    AngularFireAuthGuard
  ]
})
export class CoreModule { }
