<div
  *ngIf="tile"
  class="tile-module"
  [attr.data-tile-format]="format"
  [attr.data-tile-type]="tile.type"
  [attr.data-tile-subtype]="tile.subtype || null"
  [attr.data-tile-featured]="tile.featured ? 'true' : 'false'"
  [attr.data-tile-context]="context || null"
>
  <div class="tile__inner">

    <div class="tile__middle">

      <div class="tile__author">
        <div class="tile__author-image-container">
          <img [src]="tile.author.image.url" [alt]="tile.author.image.alt" class="tile__image" *ngIf="tile.author.image">
        </div>
        <span class="caption tile__author-name" *ngIf="tile.author">{{ tile.author.name }}</span>
      </div>

      <div
        class="tile__type"
        [class.body--2]="format === 'small' || format === 'xsmall'"
      >{{'Common.Library.Tiles.' + tile.type |translate}}</div>
      <h4
        class="tile__title"
        [class.title--h3]="format === 'medium'"
        [class.subtitle--tri]="format === 'xsmall'"
        mathjax
      >{{ tile.title }}</h4>
      <div class="tile__infos caption" *ngIf="tile.thematic || tile.levels">
        <span class="tile__thematic" *ngIf="tile.thematic">
           {{ 'Parents.Thematics.'+tile.thematic+'.label' | translate }}
        </span>
        <span class="tile__level" *ngFor="let level of tile.levels">
          <span class="tile__level-years" *ngIf="level.years">{{ level.years }}</span>
        </span>
      </div>

    </div>

    <a [routerLink]="tile.url" class="tile__link">
      <span class="tile__arrow icon icon-arrow"></span>
    </a>

  </div>
</div>
