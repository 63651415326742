import { Injectable } from '@angular/core';

declare var dataLayer: any[];

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  constructor() { }

  pushVar(obj: any) {
    dataLayer.push(obj);
  }
}
