<div
  class="flo-head"
  [ngClass]="[
    classes || '',
    (face.eyelidR < 0.2 ? 'state-flo-right-eye-closed' : ''),
    (face.eyelidL < 0.2 ? 'state-flo-left-eye-closed' : ''),
    (minimized ? 'state-flo-rotate' : '')
  ]"
[ngStyle]="!minimized &&{
    'transform': 'rotate(' + (minimized ? 0 : face.bodyRotation) + 'deg)'
  }"

>




  <!-- EYEBROWS -->
  <svg
    class="flo-head__fin"
    viewBox="0 0 287.42 142.36"
    [ngStyle]="{
      'transform': 'rotate(' + face.finRotate + 'deg)'
    }"
  >
    <path
      d="M287.38,98.6c.46-4.43-2.5-6.08-6-7.35-4.15-1.52-8.26-3.12-12.4-4.65q-73-27-146-53.94Q80.81,17.09,38.66,1.49c-6.95-2.58-12.34-2.54-15.3,6-1.52,8.54-3,17.08-4.57,25.62Q12.66,66.62,6.47,100.08c-1.89,10.68-3.75,21.37-5.69,32-1.84,10.15-1.07,11.13,9.28,9.73q96.17-13,192.32-26.22,38.73-5.3,77.46-10.73C284.94,104.19,287,102.43,287.38,98.6Z"
    />
  </svg>


  <!-- FACE -->
  <div
    class="flo-head__face"
    [ngStyle]="{
      'transform': 'translate(' + face.facePositionX + '%, ' + face.facePositionY + '%) scale(' + face.faceScale + ')'
    }"
  >
    <!-- EYEBROWS -->
    <div
      class="flo-head__visage flo-head__visage--eyebrows"
      [ngStyle]="{'transform': 'translateY(' + -face.eyebrowDistance + '%) scale('+(minimized ? 0 : 1)+')'}"
    >
      <svg
        class="flo-head__visage--r-eyebrow"
        viewBox="0 0 147 38.49"
        [ngStyle]="{'transform': 'rotate(' + face.eyebrowRotationR + 'deg) scaleX(-1)'}"
      >
        <path
          fill="none"
          stroke="black"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="10"
          [attr.d]="eyebrowExpressions[face.eyebrowExpressionR]"
        />
      </svg>

      <svg
        class="flo-head__visage--l-eyebrow"
        viewBox="0 0 147 38.49"
        [ngStyle]="{'transform': 'rotate(' + face.eyebrowRotationL + 'deg)'}"
      >
        <path
          fill="none"
          stroke="black"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="10"
          [attr.d]="eyebrowExpressions[face.eyebrowExpressionL]"
        />
      </svg>
    </div>
    <!-- END EYEBROWS -->


    <!-- EYES -->
    <div
      class="flo-head__visage flo-head__visage--eyes"
    >
      <!-- LEFT EYE-->
      <svg
        class="flo-head__visage flo-head__visage--left-eye"
        viewBox="0 0 44 44"
      >
        <circle
          class="flo-head__visage--white-eye"
          fill="white"
          cx="22" cy="22" r="22"
        />
        <circle
          class="flo-head__visage--retina"
          fill="black"
          [ngStyle]="{'transform': 'scale3d('+face.retinaScale+', '+face.retinaScale+', 1) translate3d(' + face.retinasPositionX + '%, ' + face.retinasPositionY + '%, 0)'}"
          cx="22" cy="22" r="11"
        />
        <rect
          [ngStyle]="{
            'transform': 'translate3d(-0.1rem, ' + ((face.eyelidL * -50) -1) + '%, 0) scale3d(1.5,' + face.upperEyelidL + ', 1)'
          }"
          class="flo-head__visage--upperEyelid"
          width="44"
          height="22"
          fill="#05bf8e"
        />
        <path
          [ngStyle]="{
            'transform': 'translate3d(-0.1rem, 1px, 0) scale3d(1.5,' + (1 - face.eyelidL) + ', 1)'
          }"
          class="flo-head__visage--lowerEyelid"
          d="M44,44H0V21s7-8,22-8,22,8,22,8Z"
          fill="#05bf8e"
        />
      </svg>
      <svg
        class="flo-head__visage flo-head__visage--left-c-eye"
        viewBox="0 0 522.29 277.52"
      >
        <path [attr.d]="eyesClosedTypes[face.eyeClosedType]" />
      </svg>
      <!-- END LEFT EYE-->

      <!-- RIGHT EYE-->
      <svg
        class="flo-head__visage flo-head__visage--right-eye"
        viewBox="0 0 44 44"
      >
        <circle
          class="flo-head__visage--white-eye"
          fill="white"
          cx="22" cy="22" r="22"
        />
        <circle
          class="flo-head__visage--retina"
          fill="black"
          [ngStyle]="{'transform': 'scale3d('+face.retinaScale+', '+face.retinaScale+', 1) translate3d(' + face.retinasPositionX + '%, ' + face.retinasPositionY + '%, 0)'}"
          cx="22" cy="22" r="11"
        />

        <rect
          [ngStyle]="{
            'transform': 'translate3d(-0.1rem, ' + ((face.eyelidR * -50) - 1) + '%, 0) scale3d(1.5,' + face.upperEyelidR + ', 1)'
          }"
          class="flo-head__visage--upperEyelid"
          width="44"
          height="22"
          fill="#05bf8e"
        />
        <path
          [ngStyle]="{
            'transform': 'translate3d(-0.1rem, 1px, 0) scale3d(1.5,' + (1 - face.eyelidR) + ', 1)'
          }"
          class="flo-head__visage--lowerEyelid"
          d="M44,44H0V21s7-8,22-8,22,8,22,8Z"
          fill="#05bf8e"
        />
      </svg>
      <svg
        class="flo-head__visage flo-head__visage--right-c-eye"
        viewBox="0 0 522.29 277.52"
      >
        <path [attr.d]="eyesClosedTypes[face.eyeClosedType]" />
      </svg>
      <!-- END RIGHT EYE-->
    </div>
    <!-- END EYES-->


    <!-- NOSE -->
    <svg
      class="flo-head__visage flo-head__visage--nose"
      viewBox="0 0 92.16 51.39"
    >
      <path
        d="M87.66,4.75,27,4.5s-22-.22-22.53,20.76C3.93,47.89,27,46.8,27,46.8l60.62.09"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-width="9"
      />
    </svg>
    <!-- END NOSE-->


    <!-- MOUTH -->
    <svg
      class="flo-head__visage flo-head__visage--mouth"
      viewBox="0 0 351.18 163.63"
      [ngClass]="
        (minimized ? 'state-flo-minimized' : '')
      "
    >
      <path
        [attr.d]="face.mouthExpression"
      />
    </svg>
    <!-- END MOUTH-->

    <ng-content></ng-content>

  </div>
  <!-- END FACE-->

</div>
<!-- END HEAD-->
