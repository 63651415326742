import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToolsService } from '../../../services/services';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit, OnDestroy {

  constructor(private tools: ToolsService) {
  }

  ngOnInit() {
    this.tools.addBodyClass('error');
  }

  ngOnDestroy() {
    this.tools.removeBodyClass('error');
  }
}
