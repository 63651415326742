import { Component, Input} from '@angular/core';
import { Topic } from '@app/shared/enums';

@Component({
  selector: 'app-hub-redirect',
  templateUrl: './hub-redirect.component.html',
  styleUrls: ['./hub-redirect.component.scss']
})
export class HubRedirectComponent  {
  @Input() isThemed = false;
  @Input() listItems;
  @Input() classModifier = '';
  @Input() isOverflow = false;
  topic = Topic;

  constructor() {
  }

  itemTheme(item) {
    return item.theme ? item.theme : item.label;
  }
}
