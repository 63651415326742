import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  constructor() { }
  static log(msg: string): void {
    if (!environment.production) {
      console.log(msg);
    }
  }
}
