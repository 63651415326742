<div
  *ngIf="tile"
  class="tile-module"
  [attr.data-tile-format]="format"
  [attr.data-tile-type]="tile.type"
  [attr.data-tile-subtype]="tile.subtype || null"
  [attr.data-tile-featured]="tile.featured ? 'true' : 'false'"
  [attr.data-tile-context]="context || null"
  [attr.data-tile-variant]="tile.variant || null"
  [attr.data-theme]="tile.topic || null"
>
  <div class="tile__inner">

    <div class="tile__top">
      <div class="tile__top-img-container" *ngIf="tile.image">
        <img [src]="tile.image.url" [alt]="tile.image.alt" class="tile__image">
      </div>
    </div>

    <div class="tile__content">
      <div class="tile__middle">
        <h4
          class="tile__title"
          [class.title--h3]="format === 'medium'"
          [class.subtitle--tri]="format === 'xsmall'"
          mathjax
        >{{ tile.title }}</h4>
        <p class="tile__description" *ngIf="tile.description">{{ tile.description }}</p>
        <div class="tile__infos caption" *ngIf="tile.topic || tile.levels">
          <span class="tile__topic" *ngIf="tile.topic">
            {{'Common.Library.' + tile.topic |translate}}
          </span>
         <!-- No level labels on those tiles in sketch
          <span class="tile__level" *ngFor="let level of tile.levels">
            <span class="tile__level-label" *ngIf="level.label">{{ level.label }}</span>
            <span class="tile__level-years" *ngIf="level.years">{{ level.years }}</span>
          </span>
          -->
        </div>
        <ng-container *ngIf="format === 'large'">

          <!-- Play -->
          <button class="tile__button btn btn--sm">
            {{'Common.Actions.read_more'|translate}}
          </button>

          <!-- Download - Deactivated for now 
          <div class="tile__downloads tile_downloads-middle" *ngIf="tile.variant === 'download' && (tile.appUrls.ios || tile.appUrls.android)">
            <span class="tile__download-caption caption">{{'Common.Actions.download_on'|translate}}</span>
            <a
              *ngIf="tile.appUrls.ios"
              class="tile__download icon icon-os-apple"
              [href]="tile.appUrls.ios"
              target="_blank"
              rel="noreferrer noopener"
            ></a>
            <a
              *ngIf="tile.appUrls.android"
              class="tile__download icon icon-os-android"
              [href]="tile.appUrls.android"
              target="_blank"
              rel="noreferrer noopener"
            ></a>
          </div> 
          -->
        </ng-container>
      </div>

      <div class="tile__bottom">

        <!-- Play -->
        <ng-container>
          <button class="tile__link-bottom link link--sm link--active">
            <span class="link__text">{{'Common.Actions.play_game'|translate}}</span>
          </button>
          <button class="tile__button btn btn--sm btn--full">
            {{'Common.Actions.read_more'|translate}}
          </button>
        </ng-container>

        <!-- Download - Deactivated for now 
        <div class="tile__downloads" *ngIf="tile.variant === 'download' && (tile.appUrls.ios || tile.appUrls.android)">
          <span class="tile__download-caption caption">{{'Common.Actions.download_on'|translate}}</span>
          <a
            *ngIf="tile.appUrls.ios"
            class="tile__download icon icon-os-apple"
            [href]="tile.appUrls.ios"
            target="_blank"
            rel="noreferrer noopener"
          ></a>
          <a
            *ngIf="tile.appUrls.android"
            class="tile__download icon icon-os-android"
            [href]="tile.appUrls.android"
            target="_blank"
            rel="noreferrer noopener"
          ></a>
        </div>
        -->
      </div>
    </div>

    <a
      [href]="tile.url"
      class="tile__link"
      rel="noreferrer noopener"
    >
      <span class="tile__arrow icon icon-arrow"></span>
    </a>
  </div>
</div>
