import { Component, Input } from '@angular/core';
import { Face } from '../flo.types';

@Component({
  selector: 'app-flo-head',
  templateUrl: './flo-head.component.html',
  styleUrls: ['./flo-head.component.scss']
})
export class FloHeadComponent {
  @Input() classes: string;
  @Input() minimized: boolean;
  @Input() face: Face;
  @Input() asleep : boolean;

  eyebrowExpressions: object;
  mouthExpressions: object;
  eyesClosedTypes: object;

  constructor() {
    this.eyebrowExpressions = {
      shortCurved: 'M67,15.77a113.09,113.09,0,0,1,40.38-8c20.83,0,34.62,7,34.62,7',
      curved: 'M34.9,18.91A123.31,123.31,0,0,1,87,7.2c34.21,0,53.44,11.66,53.44,11.66',
      straight: 'M35.91,15.61s28.09.5,51.5.5h52',
      wave: 'M5,32.23s29.3,6.49,67-10c48-21,70-8,70-8'
    };
    this.eyesClosedTypes = {
      curvedUp: 'M251.55,81.53c-94.15,0-177.76,22.18-236.56,63a18,18,0,0,0,20.52,29.59c28.07-19.47,62.84-34.25,103.36-43.93,38.62-9.23,80.6-13.43,124.75-12.49,87.61,1.85,171.9,23.72,231.25,60a18,18,0,0,0,18.77-30.71C449,107.44,358.17,83.65,264.38,81.66,260.09,81.57,255.8,81.53,251.55,81.53Z',
      curvedDown: 'M251.55,180.29c-94.15,0-177.76-22.19-236.56-63A18,18,0,1,1,35.51,87.74C63.58,107.2,98.35,122,138.87,131.66c38.62,9.23,80.6,13.44,124.75,12.5,87.61-1.86,171.9-23.72,231.25-60a18,18,0,0,1,18.77,30.72C449,154.38,358.17,178.16,264.38,180.15,260.09,180.24,255.8,180.29,251.55,180.29Z',
      freak: 'M32.43,211.33a17.15,17.15,0,0,1-2.16-34.17l382.07-49.08L39.51,35.47a17.16,17.16,0,1,1,8.27-33.3l461.5,114.64a17.15,17.15,0,0,1-1.95,33.66L34.64,211.18A16.52,16.52,0,0,1,32.43,211.33Z',
      squint: 'M514.77,117.12c-26.63-31.89-62.91-58.3-105.13-76.52-41.2-17.76-88.16-27.85-135.8-29.16-50-1.37-100.32,6.93-145.43,24C79.4,54,37.77,81.94,4.67,118.43a18,18,0,0,0,26.66,24.18c29.24-32.23,66.2-57,109.84-73.49,40.76-15.44,86.29-22.94,131.68-21.69C316,48.62,358.34,57.69,395.38,73.66a264.43,264.43,0,0,1,58.56,34.53A496.78,496.78,0,0,0,313.6,123.35c-84.65,22-151.21,65.54-192.47,126a18,18,0,1,0,29.74,20.28c81.48-119.44,257.86-137.25,349.89-120.43a18,18,0,0,0,14-32.11Z',
      straight: 'M251.55,112.53c-94.15,0-146.26.11-219.26,2.11-11,.3-25,1-25,16,0,13,14.06,20,24,20,41,0,72,0,118-1,42-.92,70.18-1.92,114.33-1,87.61,1.85,179.67,0,238.67,2,9.94.33,20-3.88,20-20,0-15-12.06-15.7-22-16-66-2-142.12,0-235.91-2C260.09,112.57,255.8,112.53,251.55,112.53Z'
    }
  }
}
