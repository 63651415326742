import { Component, Input } from '@angular/core';
import { Tile } from '@app/models/content';
import {TileContext, TileFormat} from "@app/shared/enums";

@Component({
  selector: 'app-tile-file',
  templateUrl: './tile-file.component.html',
  styleUrls: ['./tile-file.component.scss']
})
export class TileFileComponent {

  @Input() tile: Tile;
  @Input() format: TileFormat;
  @Input() context: TileContext;

  constructor() { }
}
