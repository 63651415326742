import { Component, Input } from '@angular/core';
import { Tile } from '@app/models/content';
import {TileContext, TileFormat} from '@app/shared/enums';

@Component({
  selector: 'app-tile-directory',
  templateUrl: './tile-directory.component.html',
  styleUrls: ['./tile-directory.component.scss']
})
export class TileDirectoryComponent {

  @Input() tile: Tile;
  @Input() format: TileFormat;
  @Input() context: TileContext;

  constructor() { }
}
