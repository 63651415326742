import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AvatarComponent implements OnInit {
  @Input() id: number;
  @Input() name?: string;
  @Input() format: 'small' | 'medium' | 'large';
  constructor() {}

  ngOnInit() {}

  getFirstLetter() {
    return this.name.charAt(0);
  }
}
