import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SearchComponent} from './components/search/search.component';
import {LayoutComponent} from '@app/core/components/layout/layout.component';
import {FooterComponent} from '@app/core/components/footer/footer.component';
import {BaseSectionComponent} from '@app/shared/components/base-section/base-section.component';
import {AnchorNavigationComponent} from '@app/shared/components/sections/anchor-navigation/anchor-navigation.component';
import {TextComponent} from '@app/shared/components/sections/text/text.component';
import {LinksModuleComponent} from '@app/shared/components/sections/links-module/links-module.component';
import {MultiColumnComponent} from '@app/shared/components/sections/multi-column/multi-column.component';
import {ImageComponent} from '@app/shared/components/sections/image/image.component';
import {VideoComponent} from '@app/shared/components/sections/video/video.component';
import {ListStepsComponent} from '@app/shared/components/sections/list-steps/list-steps.component';
import {BlocSpecialComponent} from '@app/shared/components/sections/bloc-special/bloc-special.component';
import {AnimateOnScrollComponent} from '@app/shared/components/animate-on-scroll/animate-on-scroll.component';
import {SwiperModule} from 'ngx-swiper-wrapper';
import {SliderComponent} from '@app/shared/components/slider/slider.component';
import {ExerciseComponent} from '@app/shared/components/sections/exercise/exercise.component';
import {TagListComponent} from '@app/shared/components/ui/tag-list/tag-list.component';
import {TileComponent} from '@app/shared/components/tile/tile.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {ModalComponent} from '@app/shared/components/modal/modal.component';
import {NewsletterComponent} from '@app/shared/components/newsletter/newsletter.component';
import {SpinnerComponent} from '@app/shared/components/ui/spinner/spinner.component';
import {HubRedirectComponent} from '@app/shared/components/hub-redirect/hub-redirect.component';
import {ButtonComponent} from './components/button/button.component';
import {TileFileComponent} from '@app/shared/components/tile-file/tile-file.component';
import {TileExerciseComponent} from '@app/shared/components/tile-exercise/tile-exercise.component';
import {TileRevisionComponent} from '@app/shared/components/tile-revision/tile-revision.component';
import {TileVideoComponent} from '@app/shared/components/tile-video/tile-video.component';
import {TileGameComponent} from '@app/shared/components/tile-game/tile-game.component';
import {TileChronicleComponent} from '@app/shared/components/tile-chronicle/tile-chronicle.component';
import {TileDirectoryComponent} from '@app/shared/components/tile-directory/tile-directory.component';
import {TileArticleComponent} from '@app/shared/components/tile-article/tile-article.component';
import {TileVideoParentComponent} from '@app/shared/components/tile-video-parent/tile-video-parent.component';
import { RangeComponent } from './components/range/range.component';
import { AvatarsComponent } from './components/avatars/avatars.component';
import { SkillsComponent } from './components/skills/skills.component';
import { LittleFloComponent } from '@app/shared/components/flo/little-flo/little-flo.component';
import { FloHeadComponent } from '@app/shared/components/flo/flo-head/flo-head.component';
import { FloLimbsComponent } from '@app/shared/components/flo/flo-limbs/flo-limbs.component';
import { AuthFloComponent } from '@app/shared/components/flo/auth-flo/auth-flo.component';
import { HomeFloComponent } from '@app/shared/components/flo/home-flo/home-flo.component';
import { SearchFloComponent } from '@app/shared/components/flo/search-flo/search-flo.component';
import { HelpDialogComponent } from '@app/shared/components/help-dialog/help-dialog.component';
import { NumericDirective } from '@app/services/validators/phone-validator';
import { ReferencesComponent } from '@app/shared/components/sections/references/references.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { HeaderMenuComponent } from './components/header-menu/header-menu.component';
import { VocabularyComponent } from '@app/shared/components/sections/vocabulary/vocabulary.component';
import { ResetBtnComponent } from '@app/shared/components/buttons/reset-btn/reset-btn.component';
import { SearchBtnComponent } from '@app/shared/components/buttons/search-btn/search-btn.component';
import {SharerComponent} from '@src/app/shared/components/sharer/sharer.component';
import { MathJaxModule } from 'ngx-mathjax';
import { HubRedirectTopicsTextComponent } from '@app/shared/components/hub-redirect-topics-text/hub-redirect-topics-text.component';
import { WysiwygContentComponent } from '@app/shared/components/wysiwyg-content/wysiwyg-content.component';
import { HeroComponent } from '@app/shared/components/hero/hero.component';

@NgModule({
  declarations: [
    FooterComponent,
    LayoutComponent,
    SearchComponent,
    BaseSectionComponent,
    AnchorNavigationComponent,
    TextComponent,
    LinksModuleComponent,
    MultiColumnComponent,
    ImageComponent,
    VideoComponent,
    ListStepsComponent,
    BlocSpecialComponent,
    AnimateOnScrollComponent,
    SliderComponent,
    ExerciseComponent,
    TagListComponent,
    TileComponent,
    ModalComponent,
    NewsletterComponent,
    SpinnerComponent,
    HubRedirectComponent,
    ButtonComponent,
    TileFileComponent,
    TileExerciseComponent,
    TileRevisionComponent,
    TileVideoComponent,
    TileGameComponent,
    TileChronicleComponent,
    TileDirectoryComponent,
    TileArticleComponent,
    TileVideoParentComponent,
    RangeComponent,
    AvatarsComponent,
    SkillsComponent,
    LittleFloComponent,
    FloHeadComponent,
    FloLimbsComponent,
    AuthFloComponent,
    HomeFloComponent,
    SearchFloComponent,
    HelpDialogComponent,
    NumericDirective,
    ReferencesComponent,
    AvatarComponent,
    HeaderMenuComponent,
    VocabularyComponent,
    ResetBtnComponent,
    SearchBtnComponent,
    SharerComponent,
    HubRedirectTopicsTextComponent,
    WysiwygContentComponent,
    HeroComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SwiperModule,
    NgSelectModule,
    MathJaxModule.forChild()
  ],
  exports: [
    FooterComponent,
    LayoutComponent,
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    SearchComponent,
    BaseSectionComponent,
    AnchorNavigationComponent,
    TextComponent,
    LinksModuleComponent,
    MultiColumnComponent,
    ImageComponent,
    VideoComponent,
    ListStepsComponent,
    BlocSpecialComponent,
    AnimateOnScrollComponent,
    SliderComponent,
    ExerciseComponent,
    TagListComponent,
    TileComponent,
    NgSelectModule,
    NewsletterComponent,
    SpinnerComponent,
    HubRedirectComponent,
    ButtonComponent,
    TileFileComponent,
    TileExerciseComponent,
    TileRevisionComponent,
    TileVideoComponent,
    TileVideoParentComponent,
    TileArticleComponent,
    TileGameComponent,
    TileChronicleComponent,
    TileDirectoryComponent,
    RangeComponent,
    AvatarsComponent,
    SkillsComponent,
    LittleFloComponent,
    FloHeadComponent,
    FloLimbsComponent,
    AuthFloComponent,
    HomeFloComponent,
    SearchFloComponent,
    HelpDialogComponent,
    NumericDirective,
    ReferencesComponent,
    AvatarComponent,
    HeaderMenuComponent,
    VocabularyComponent,
    ResetBtnComponent,
    SearchBtnComponent,
    SharerComponent,
    HubRedirectTopicsTextComponent,
    WysiwygContentComponent,
    HeroComponent
  ]
})
export class SharedModule { }
