<div
  *ngIf="tile"
  class="tile-module"
  [attr.data-tile-format]="format"
  [attr.data-tile-type]="tile.type"
  [attr.data-tile-subtype]="tile.subtype || null"
  [attr.data-tile-featured]="tile.featured ? 'true' : 'false'"
  [attr.data-tile-context]="context || null"
>
  <div class="tile__inner">

    <div class="tile__top">
      <div
        class="tile__top-img-container"
         *ngIf="tile.image"
        [ngStyle]="{
          'background': 'url('+tile.image.url+')',
          'background-size': 'cover',
          'background-position': 'center center'
        }"
      >
      </div>
    </div>

    <div class="tile__middle">
      <div
        class="tile__type"
        [class.body--2]="format === 'small' || format === 'xsmall'"
      >{{'Common.Library.Tiles.' + tile.type |translate}}</div>
      <h4
        class="tile__title"
        [class.title--h3]="format === 'medium' || format === 'large'"
        [class.subtitle--tri]="format === 'xsmall'"
        mathjax
      >{{ tile.title }}</h4>
      <div class="tile__infos caption" *ngIf="tile.thematic">
        <span class="tile__thematic" *ngIf="tile.thematic">
          {{ 'Parents.Thematics.'+tile.thematic+'.label' | translate }}
        </span>
      </div>
      <button class="tile__button btn btn--sm" *ngIf="format === 'large'">
        {{'Common.Actions.read_article'|translate}}
      </button>
    </div>


    <a [routerLink]="tile.url" class="tile__link">
      <span class="tile__arrow icon icon-arrow"></span>
    </a>

  </div>
</div>
