import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { EMPTY } from 'rxjs';

import { LoggerService } from '../services/services';

@Injectable()
export class SelectivePreloadingStrategy implements PreloadingStrategy {
  preloadedModules: string[] = [];

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (route.data && route.data.preload) {
      // add the route path to our preloaded module array if not already added
      if (!this.isAlreadyPreloaded(route.path)) {
        this.preloadedModules.push(route.path);
        // log the route path to the console
        LoggerService.log('Preloaded: ' + route.path);
      }
      return load();
    } else {
      return EMPTY;
    }
  }

  isAlreadyPreloaded(module: string): boolean {
    for (const preloadedModule of this.preloadedModules) {
      if (module === preloadedModule) {
        return true;
      }
    }
    return false;
  }
}
