import { Component, Input, OnInit } from '@angular/core';
import { ExerciseModule, ListStepsModule } from '@app/models/content';
import { NoSpacingBreakpoint } from '@app/shared/enums';

@Component({
  selector: 'app-base-section',
  templateUrl: './base-section.component.html',
  styleUrls: ['./base-section.component.scss']
})
export class BaseSectionComponent implements OnInit {

  @Input() isWrapping: string;
  @Input() noLeft: NoSpacingBreakpoint;
  @Input() noRight: NoSpacingBreakpoint;
  @Input() isNarrow = false;
  @Input() module: any;
  @Input() isFirst = false;
  @Input() isLast = false;
  @Input() sectionClass: string;
  @Input() bgColor: string;
  @Input() hasSlider = false;
  @Input() id: string;

  constructor() { }

  ngOnInit() {
    if (!this.isWrapping && this.module) {
      this.isWrapping =
        this.module.type + ( this.module.subtype ? '--' + this.module.subtype : '');
    }

    switch (this.isWrapping) {
      case 'blocSpecial--important':
        this.bgColor = '100';
        break;
      case 'blocSpecial--regle':
        this.bgColor = '50';
        this.isHasSlider();
        break;
      case 'exerciseList':
        this.bgColor = '50';
        if ((this.module as ExerciseModule).isSlider) { this.hasSlider = true; }
        break;
    }

    this.setNoLeft();
    this.setNoRight();

    if (this.sectionClass) {
      this.sectionClass = `section--${this.sectionClass}`;
    }
  }

  isHasSlider() {
    for (const module of this.module.submodules as ListStepsModule[]) {
      if (module.isSlider) { this.hasSlider = true; break; }
    }
  }

  setNoLeft() {
    if (
      typeof this.noLeft === 'undefined' &&
      this.module &&
      this.isWrapping === 'blocSpecial--important'
    ) {
      this.noLeft = NoSpacingBreakpoint['min_small+1'];
    }
  }

  setNoRight() {
    if (
      typeof this.noRight === 'undefined' &&
      this.module &&
      this.hasSlider
    ) {
      if (this.isWrapping === 'blocSpecial--regle') {
        this.noRight = NoSpacingBreakpoint.all;
      } else if (this.isWrapping === 'exerciseList') {
        this.noRight = NoSpacingBreakpoint['min_small+1'];
      }
    }
  }

}
