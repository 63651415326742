<!-- Fiche -->
<app-tile-file
  *ngIf="realType === 'file'"
  [tile]="tile"
  [format]="format"
  [context]="context"
></app-tile-file>

<!-- Exercice -->
<app-tile-exercise
  *ngIf="realType === 'exercise'"
  [tile]="tile"
  [format]="format"
  [context]="context"
></app-tile-exercise>

<!-- Revision -->
<app-tile-revision
  *ngIf="realType === 'revision'"
  [tile]="tile"
  [format]="format"
  [context]="context"
></app-tile-revision>

<!-- Video -->
<app-tile-video
  *ngIf="realType === 'video'"
  [tile]="tile"
  [format]="format"
  [context]="context"
></app-tile-video>

<!-- Game -->
<app-tile-game
  *ngIf="realType === 'game'"
  [tile]="tile"
  [format]="format"
  [context]="context"
></app-tile-game>

<!-- Parents Chronicle -->
<app-tile-chronicle
  *ngIf="tile.type === 'chronicle'"
  [tile]="tile"
  [format]="format"
  [context]="context"
></app-tile-chronicle>

<!-- Parents Articles -->
<app-tile-article
  *ngIf="tile.type === 'article'"
  [tile]="tile"
  [format]="format"
  [context]="context"
></app-tile-article>

<!-- Parents Videos -->
<app-tile-video-parent
  *ngIf="tile.type === 'video-parent'"
  [tile]="tile"
  [format]="format"
  [context]="context"
></app-tile-video-parent>

<!-- Parents Directories -->
<app-tile-directory
  *ngIf="tile.type === 'directory'"
  [tile]="tile"
  [format]="format"
  [context]="context"
></app-tile-directory>
