import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Tile } from '@app/models/content';
import { TileContext, TileFormat } from '@app/shared/enums';
import {EventsService} from "@app/services/events/events.service";

@Component({
  selector: 'app-tile-exercise',
  templateUrl: './tile-exercise.component.html',
  styleUrls: ['./tile-exercise.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TileExerciseComponent implements OnInit {

  @Input() tile: Tile;
  @Input() format: TileFormat;
  @Input() context: TileContext;

  externalUrl: string;
  internalUrl: string;
  fragment: string;

  constructor(
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
    if (this.tile.openExternal) {
      this.externalUrl = this.tile.url;
    } else {
      this.internalUrl = this.tile.url;
      if (this.tile.moduleSlug && this.tile.entitySlug) {
        this.fragment = `exercise--${this.tile.moduleSlug}__${this.tile.entitySlug}`;
      }
    }
  }

  onClick() {
    this.eventsService.broadcast('app:popstate:restore-scroll');
  }

}
