<div
  *ngIf="tile"
  class="tile-module"
  [attr.data-tile-format]="format"
  [attr.data-tile-type]="tile.type"
  [attr.data-tile-subtype]="tile.subtype || null"
  [attr.data-tile-featured]="tile.featured ? 'true' : 'false'"
  [attr.data-tile-context]="context || null"
  [attr.data-tile-entity-slug]="tile.entitySlug || null"
  [attr.data-theme]="tile.topic || null"
>
  <div class="tile__inner">

    <div class="tile__top"></div>
    <div class="tile__middle">
      <div
        class="tile__type"
        [class.body--2]="format === 'small' || format === 'xsmall'"
      >
        {{'Common.Library.Tiles.' + tile.type |translate}}
      </div>
      <h4
        class="tile__title"
        [class.title--h3]="format === 'medium'"
        [class.subtitle--tri]="format === 'xsmall'"
        mathjax
      >{{ tile.title }}</h4>
      <div class="tile__infos caption" *ngIf="tile.topic || tile.levels">
        <span class="tile__topic" *ngIf="tile.topic">
          {{'Common.Library.' + tile.topic |translate}}
        </span>
        <span class="tile__level" *ngFor="let level of tile.levels">
          <span class="tile__level-label" *ngIf="level.label">{{ level.label }}</span>
          <span class="tile__level-years" *ngIf="level.years">{{ level.years }}</span>
        </span>
      </div>
      <button class="tile__button btn btn--sm" *ngIf="format === 'large'">
        {{'Common.Actions.read_more'|translate}}
      </button>
    </div>

    <!--  External  -->
    <a
      *ngIf="externalUrl"
      class="tile__link"
      [href]="externalUrl"
      target="_blank"
      rel="noreferrer noopener"
    >
      <div class="tile__arrow icon icon-arrow-external"></div>
      <div class="tile__sticker-wrapper" *ngIf="tile.featured">
        <svg class="tile__sticker">
          <use xlink:href="#sticker--haltere" />
        </svg>
      </div>
    </a>
    <!--  Internal  -->
    <a
      *ngIf="internalUrl"
      class="tile__link"
      [routerLink]="internalUrl"
      [fragment]="fragment"
      (click)="onClick()"
    >
      <div class="tile__arrow icon icon-arrow-external"></div>
      <div class="tile__sticker-wrapper" *ngIf="tile.featured">
        <svg class="tile__sticker">
          <use xlink:href="#sticker--haltere" />
        </svg>
      </div>
    </a>

  </div>
</div>
