<app-base-section [isWrapping]="'header'">
  <div class="header">
    <div class="header__left">
      <button
        id="animationMenu"
        class="header__burger"
        type="button"
        (click)="openMenu()"
      >
        <div class="header__burger__icon">
          <span class="header__burger__line"></span>
          <span class="header__burger__line"></span>
          <span class="header__burger__line"></span>
        </div>
      </button>
    </div>

    <div class="header__center">
      <a
        [routerLink]="isParentPage ? ['/parents'] : ['/']"
        data-test="header-home"
        title="Alloprof"
      >
        <svg *ngIf="isParentPage && !isMobile" class="header__logo">
          <use xlink:href="#logo__parents-nav-desktop" />
        </svg>

        <svg
          *ngIf="isParentPage && isMobile"
          class="header__logo header__logo-mobile"
        >
          <use xlink:href="#logo__parents-nav-mobile" />
        </svg>

        <img
          *ngIf="!isParentPage"
          src="assets/images/logo.svg"
          alt="Alloprof"
          class="header__logo"
        />
      </a>
    </div>

    <div class="header__right">
      <div
        class="header__right__item header__lang"
        *ngIf="tools.state === 'parent'"
      >
        <a
          class="header__link"
          data-test="header-lang"
          [routerLink]="otherLangUrl"
        >
          {{ otherLang }}
        </a>
      </div>
      <!-- TODO: [UserActivation] Désactivé temporairement pour une mise en prod d'urgence -->
      <div
        *ngIf="!isProduction"
        class="header__user"
        [ngClass]="{ 'header__user--active': active }"
      >
        <span
          class="header__user__overlay"
          *ngIf="active"
          (click)="toggleUserMenu()"
        >
        </span>
        <a
          class="header__link header__link--user"
          data-test="header-profile"
          [ngClass]="!loading && 'is-loaded'"
          (click)="toggleUserMenu()"
        >
          <div
            *ngIf="user && user.photo_url"
            class="header__avatar header__avatar--logged-in"
          >
            <app-avatar
              [id]="user.photo_url"
              [format]="'small'"
              [name]="user.photo_url === '1' ? user.nickname : null"
            ></app-avatar>
          </div>

          <svg *ngIf="!user" class="header__avatar" viewBox="0 0 25 25">
            <g transform="translate(18.000000, 18.000000)">
              <path
                class="login__state"
                d="M2-3c2.8,0,5,2.2,5,5S4.8,7,2,7h-15c-2.8,0-5-2.2-5-5s2.2-5,5-5H2z M-5.5-17c3,0,5.5,2.5,5.5,5.5
              S-2.5-6-5.5-6S-11-8.5-11-11.5S-8.5-17-5.5-17z"
              />
              <path
                class="login__stroke"
                d="M2-3c2.8,0,5,2.2,5,5S4.8,7,2,7h-15c-2.8,0-5-2.2-5-5s2.2-5,5-5H2z M2-2h-15c-2.2,0-4,1.8-4,4
              s1.8,4,4,4H2c2.2,0,4-1.8,4-4S4.2-2,2-2z M-5.5-18c3.6,0,6.5,2.9,6.5,6.5S-1.9-5-5.5-5S-12-7.9-12-11.5S-9.1-18-5.5-18z M-5.5-17
              c-3,0-5.5,2.5-5.5,5.5S-8.5-6-5.5-6S0-8.5,0-11.5S-2.5-17-5.5-17z"
              />
            </g>
          </svg>
        </a>

        <div class="header__user__menu" [ngClass]="{ active: active }">
          <ng-container *ngIf="!user">
            <a [routerLink]="['/auth/connexion']" class="btn btn--sm">{{
              'Auth.Register.login' | translate
            }}</a>
            <a
              [routerLink]="['/auth/inscription']"
              class="link link--sm link--active"
              ><span class="link__text">{{
                'Auth.Login.create_account' | translate
              }}</span></a
            >
          </ng-container>
          <ng-container *ngIf="user">
            <a [routerLink]="['/profil']" class="btn btn--sm">{{
              'Account.title' | translate
            }}</a>
            <a class="link link--sm link--active" (click)="logout()"
              ><span class="link__text">{{
                'Account.logout' | translate
              }}</span></a
            >
          </ng-container>
        </div>
      </div>

      <div class="header__search">
        <app-search-btn
          className="header__search-btn"
          (click)="openSearch()"
        ></app-search-btn>
      </div>
    </div>
  </div>
</app-base-section>
<app-header-menu></app-header-menu>
<app-site-search></app-site-search>
