<div
  class="loader"
  [class.state-loader-active]="loading"
>
  <div class="loader__twinkles">
    <svg class="loader__twinkle-big"><use xlink:href="#sparkle" /></svg>
    <svg class="loader__twinkle-small"><use xlink:href="#sparkle" /></svg>
    <svg class="loader__twinkle-medium"><use xlink:href="#sparkle" /></svg>
  </div>

  <div class="loader__bg"></div>
</div>
