<section
  class="site-search"
  [ngClass]="{
    'state-search-open': active
  }"
  data-theme="default"
>
  <!-- BG-COLOR DIV -->
  <div data-bg-color="100">
    <app-base-section>
      <!-- CONTAINER -->
      <div class="site-search__container">
        <!-- MAIN -->
        <div class="site-search__main">
          <a class="site-search__close" (click)="onClickClose()">
            <span class="icon icon-close icon--tilt"></span>
          </a>

          <!-- CONTENT -->
          <div class="site-search__content">
            <div
              class="site-search__bar"
              [ngClass]="{
                'state-search-keywords': params.keywords.trim() !== ''
              }"
            >
              <div class="form">
                <div class="form__grid">
                  <div class="form__item">
                    <input
                      class="site-search__field form__control"
                      type="text"
                      [placeholder]="translate.instant('Common.Search.title')"
                      [(ngModel)]="params.keywords"
                      (focus)="onOpen()"
                      (keyup)="onKeywordsKeyUp($event)"
                      #searchField
                    />

                    <app-reset-btn
                      className="site-search__clear"
                      (click)="onClickClearKeywords()"
                    ></app-reset-btn>

                    <app-search-btn
                      className="site-search__submit"
                    ></app-search-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /CONTENT -->

          <!-- TAGS -->
          <div class="site-search__tags">
            <!-- KEYWORDS LOADING SPINNER -->
            <div
              class="site-search__suggest-loader"
              [ngStyle]="{
                height: transitions.loading.interpolated * 1.5 + 'rem',
                'margin-bottom': transitions.loading.interpolated * 1.5 + 'rem',
                opacity: transitions.loading.interpolated
              }"
            >
              <app-spinner></app-spinner>
            </div>
            <!-- /KEYWORDS LOADING SPINNER -->

            <!-- SUGGESTIONS -->
            <div
              class="site-search__suggestions height-wrap height-wrap--bottom"
              [ngStyle]="{
                height: transitions.suggestions.interpolated + 'px',
                'margin-bottom':
                  transitions.suggestionsOpacity.interpolated * 1.5 + 'rem',
                opacity: transitions.suggestionsOpacity.interpolated
              }"
            >
              <div #suggestionsContainer>
                <div class="search__tag-list">
                  <app-tag-list
                    [tags]="searchSuggestions"
                    (onClick)="setKeywords($event.value)"
                  >
                  </app-tag-list>
                </div>
              </div>
            </div>
            <!-- /SUGGESTIONS -->

            <!-- // FIXME: enable when Popular Keywords are populated
            <div
              class="site-search__keywords"
              #tagKeywords
            >
              <h3 class="subtitle--two">{{'Common.Search.popular_keywords'|translate}}</h3>

              <div class="site-search__tag-list">
                <app-tag-list
                  [tags]="popularKeywords"
                  (onClick)="onClickKeyword($event)"
                  [focusable]="showKeywords"
                ></app-tag-list>
              </div>
            </div>
            -->

            <!-- BY TOPIC -->
            <!-- // FIXME: enable once Search Results provide results per topic -->
            <div
              class="site-search__by-topic height-wrap height-wrap--bottom"
              [ngStyle]="{
                height: transitions.tagsByTopic.interpolated + 'px',
                opacity: transitions.tagsByTopic.interpolated
              }"
            >
              <div #tagByTopic>
                <h3 class="subtitle--two">
                  {{ 'Common.Search.Results.by_topic' | translate }}
                </h3>

                <div class="site-search__topics">
                    <app-hub-redirect [listItems]="byTopic" [isThemed]="true" ></app-hub-redirect>
                </div>
              </div>
            </div>
            <!-- /BY TOPIC -->
          </div>
          <!-- TAGS -->

          <!-- NO RESULTS -->
          <div
            class="site-search__no-results height-wrap height-wrap--bottom"
            [ngStyle]="{
              height: transitions.noResults.interpolated + 'px',
              opacity: transitions.noResultsOpacity.interpolated
            }"
          >
            <div #noResultsContainer>
              <hr
                *ngIf="searchSuggestions.length"
                class="site-search__separator"
              />

              <a class="site-search__teacher btn btn--md" href="https://sp.alloprof.qc.ca/forums" target=" _blank">
                {{ 'Common.Search.Results.speak_with_teacher' | translate }}
              </a>
            </div>
          </div>
          <!-- /NO RESULTS -->
        </div>
        <!-- /MAIN -->

        <app-search-flo></app-search-flo>
      </div>
      <!-- /CONTAINER -->
    </app-base-section>
  </div>
  <!-- /BG-COLOR DIV -->

  <!-- RESULTS CONTAINER -->
  <div class="site-search__results-container">
    <!-- SEARCH RESULTS LOADER -->
    <div class="site-search__loader-wrap">
      <app-base-section isWrapping="'default'">
        <div
          class="site-search__results-loader"
          [ngStyle]="{
            height: transitions.loadingResults.interpolated * 3.5 + 'rem',
            'margin-top': transitions.loadingResults.interpolated * 3 + 'rem',
            'margin-bottom':
              transitions.loadingResults.interpolated * 3 + 'rem',
            opacity: transitions.loadingResults.interpolated
          }"
        >
          <app-spinner></app-spinner>
        </div>
      </app-base-section>
    </div>
    <!-- /SEARCH RESULTS LOADER -->

    <!-- TOP RESULTS -->
    <div
      class="site-search__results site-search__results--top height-wrap"
      [ngStyle]="{
        height: transitions.topResults.interpolated + 'px',
        transform:
          'translateY(' +
          (1 - transitions.topResultsOpacity.interpolated) * 2 +
          'rem)',
        opacity: transitions.topResultsOpacity.interpolated
      }"
    >
      <div #topResultsContainer>
        <app-base-section isWrapping="'default'">
          <h3 class="title title--h3">
            {{ 'Common.Search.Results.top_results' | translate }}
          </h3>

          <div class="site-search__result-items grid grid--tiles grid--4col">
            <!-- FIXME: replace with slider component once it's finished! -->
            <ng-container *ngFor="let tile of results.top">
              <div class="site-search__recent-item grid__item">
                <app-tile
                  [tile]="tile"
                  [format]="'medium'"
                  context="'search'"
                ></app-tile>
              </div>
            </ng-container>
            <!-- END FIXME: -->
          </div>
        </app-base-section>
      </div>
    </div>
    <!-- /TOP RESULTS -->


    <!-- FILE RESULTS -->
    <div
      class="site-search__results height-wrap"
      [ngStyle]="{
        height: transitions.filesResults.interpolated + 'px',
        transform:
          'translateY(' +
          (1 - transitions.filesResultsOpacity.interpolated) * 2 +
          'rem)',
        opacity: transitions.filesResultsOpacity.interpolated
      }"
    >
      <div #fileResultsContainer>
        <app-base-section isWrapping="'default'">
          <hr class="site-search__separator" />
          <h3 class="subtitle--two">
            {{ 'Common.Search.Results.files_results' | translate }}
          </h3>

          <div class="site-search__result-items grid grid--tiles grid--4col">
            <ng-container *ngFor="let item of results.file">
              <div class="site-search__recent-item grid__item">
                <app-tile
                  [tile]="item"
                  [format]="'xsmall'"
                  [context]="'search'"
                ></app-tile>
              </div>
            </ng-container>
          </div>

          <a class="link link--sm link--active" [routerLink]="'/bv/fiches'">
            <span class="link__text">{{
              'Common.Search.Results.files_view_all' | translate
            }}</span>
          </a>
        </app-base-section>
      </div>
    </div>
    <!-- /FILE RESULTS -->

    <!-- VIDEO RESULTS -->
    <div
      class="site-search__results height-wrap"
      [ngStyle]="{
        height: transitions.videosResults.interpolated + 'px',
        transform:
          'translateY(' +
          (1 - transitions.videosResultsOpacity.interpolated) * 2 +
          'rem)',
        opacity: transitions.videosResultsOpacity.interpolated
      }"
    >
      <div #videoResultsContainer>
        <app-base-section isWrapping="'default'">
          <hr class="site-search__separator" />
          <h3 class="subtitle--two">
            {{ 'Common.Search.Results.videos_results' | translate }}
          </h3>

          <div class="site-search__result-items grid grid--tiles grid--4col">
            <ng-container *ngFor="let item of results.video">
              <div class="site-search__recent-item grid__item">
                <app-tile
                  [tile]="item"
                  [format]="'xsmall'"
                  [context]="'search'"
                ></app-tile>
              </div>
            </ng-container>
          </div>

          <a class="link link--sm link--active" [routerLink]="'/bv/videos'">
            <span class="link__text">{{
              'Common.Search.Results.videos_view_all' | translate
            }}</span>
          </a>
        </app-base-section>
      </div>
    </div>
    <!-- /VIDEO RESULTS -->

    <!-- EXERCISE RESULTS -->
    <div
      class="site-search__results height-wrap"
      [ngStyle]="{
        height: transitions.exercisesResults.interpolated + 'px',
        transform:
          'translateY(' +
          (1 - transitions.exercisesResultsOpacity.interpolated) * 2 +
          'rem)',
        opacity: transitions.exercisesResultsOpacity.interpolated
      }"
    >
      <div #exerciseResultsContainer>
        <app-base-section isWrapping="'default'">
          <hr class="site-search__separator" />
          <h3 class="subtitle--two">
            {{ 'Common.Search.Results.exercises_results' | translate }}
          </h3>

          <div class="site-search__result-items grid grid--tiles grid--4col">
            <ng-container *ngFor="let item of results.exercise">
              <div class="site-search__recent-item grid__item">
                <app-tile
                  [tile]="item"
                  [format]="'xsmall'"
                  [context]="'search'"
                ></app-tile>
              </div>
            </ng-container>
          </div>

          <a class="link link--sm link--active" [routerLink]="'/bv/exercices'">
            <span class="link__text">{{
              'Common.Search.Results.exercises_view_all' | translate
            }}</span>
          </a>
        </app-base-section>
      </div>
    </div>
    <!-- /EXERCISE RESULTS -->

    <!-- GO TO FORUM -->
    <div
      class="site-search__results"
      [ngStyle]="{
        transform:
          'translateY(' +
          (1 - transitions.topResultsOpacity.interpolated) * 2 +
          'rem)',
        opacity: transitions.topResultsOpacity.interpolated
      }"
    >
      <app-base-section isWrapping="'default'">
        <hr class="site-search__separator" />

        <a class="site-search__forum btn btn--md" href="https://sp.alloprof.qc.ca/forums" target=" _blank">
          {{ 'Common.Search.Results.go_to_forum' | translate }}
        </a>
      </app-base-section>
    </div>
    <!-- /GO TO FORUM -->

    <!-- EXTRAS -->
    <div
      class="site-search__extras height-wrap"
      [ngStyle]="{
        height: transitions.extras.interpolated + 'px',
        opacity: transitions.extrasOpacity.interpolated
      }"
    >
      <div #extrasContainer>
        <app-base-section isWrapping="'default'">
          <div class="site-search__recent">
            <h3 class="title title--h3">
              {{ 'Common.Search.recent' | translate }}
            </h3>

            <div class="site-search__recent-items grid grid--tiles grid--4col">
              <ng-container *ngFor="let item of recentItems">
                <div class="site-search__recent-item grid__item">
                  <app-tile
                    [tile]="item"
                    [format]="'xsmall'"
                    [context]="'search'"
                  ></app-tile>
                </div>
              </ng-container>
            </div>
          </div>
          <!-- /RECENTLY VIEWED -->

          <!-- SEARCH HISTORY -->
          <div class="site-search__history" *ngIf="searchHistory.length">
            <div class="site-search__search-history">
              <h3 class="subtitle--two">
                {{ 'Common.Search.search_history' | translate }}
              </h3>

              <div class="site-search__tag-list">
                <app-tag-list
                  [tags]="searchHistory"
                ></app-tag-list>
              </div>

              <button
                class="site-search__clear-history link link--sm"
                type="button"
                (click)="onClickClearHistory()"
              >
                <span class="icon icon-refresh-sm"></span>
                <span class="link__text">{{
                  'Common.Search.clear_history' | translate
                }}</span>
              </button>
            </div>
          </div>
          <!-- /SEARCH HISTORY -->
        </app-base-section>
      </div>
    </div>
    <!-- /EXTRAS -->
  </div>
  <!-- /RESULTS CONTAINER -->
</section>
