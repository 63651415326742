
<div
  *ngIf="tile"
  class="tile-module"
  [attr.data-tile-format]="format"
  [attr.data-tile-type]="tile.type"
  [attr.data-tile-subtype]="tile.subtype || null"
  [attr.data-tile-featured]="tile.featured ? 'true' : 'false'"
  [attr.data-tile-context]="context || null"
  [attr.data-theme]="tile.topic || null"
>
  <div class="tile__inner">

    <div class="tile__wrapper-marker">
      <div class="wrapper-marker__inner"></div>
      <div class="wrapper-marker__shadow"></div>
    </div>

    <div class="tile__middle">
      <div
        class="tile__type"
        [class.body--2]="format === 'small' || format === 'xsmall'"
      >{{'Common.Library.Tiles.revision'|translate}}</div>
      <h4
        class="tile__title"
        [class.title--h3]="format === 'medium'"
        [class.subtitle--tri]="format === 'xsmall'"
        mathjax
      >{{ tile.title }}</h4>
      <div class="tile__infos caption" *ngIf="tile.topic || tile.levels">
        <span class="tile__topic" *ngIf="tile.topic">
          {{'Common.Library.' + tile.topic |translate}}
        </span>
        <span class="tile__level" *ngFor="let level of tile.levels">
          <span class="tile__level-label" *ngIf="level.label">{{ level.label }}</span>
          <span class="tile__level-years" *ngIf="level.years">{{ level.years }}</span>
        </span>
      </div>
      <button class="tile__button btn btn--sm" *ngIf="format === 'large'">
        {{'Common.Actions.read_more'|translate}}
      </button>
    </div>
    <div class="tile__bottom" >
      <button class="tile__button btn btn--sm btn--full" *ngIf="format === 'medium'">
        {{'Common.Actions.read_more'|translate}}
      </button>
    </div>

    <a [routerLink]="tile.url" class="tile__link">
      <span class="tile__arrow icon icon-arrow"></span>
    </a>

  </div>
</div>

