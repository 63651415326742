<div class="tag-list">
  <div
    class="tag-list__btn btn-wrapper"
    *ngFor="let tag of tags |  slice:0:taglimit"
  >
    <button
      class="btn btn--xs"
      type="button"
      (click)="onTagClick($event, tag)"
      [tabindex]="(focusable ? 0 : -1)"
    >
      {{ tag }}
    </button>
  </div>
  <div
    class="tag-list__show-more"
    *ngIf="tags.length > 10 && taglimit <=10"
  >
    <a class="link link--active link--tag-more" (click)="onClickShowMore()">
      <span  class="link__text">{{'Common.Actions.show_more'|translate}}</span>
    </a>
  </div>
</div>
