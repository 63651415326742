<div
  class="search-flo"
  [ngStyle]="{
    transform: 'translate3d(' + posX.value + '%, 0, 0)'
  }"
>
  <div
    class="search-flo__container"
    [ngClass]="animation ? 'state-flo-' + animation : ''"
  >
    <div class="search-flo__messages">
      <div class="search-flo__message search-flo__message--no-result">
        <p class="subtitle subtitle--two">
          {{ 'Common.Search.Results.flo_no_results' | translate }}
        </p>
      </div>
    </div>

    <app-flo-head
      [minimized]="false"
      [face]="face"
      [classes]="animation ? 'state-flo-head-' + animation : ''"
    >
    </app-flo-head>

    <app-flo-limbs [limbs]="limbs"> </app-flo-limbs>
  </div>
</div>
