import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RafService {
  instance: RafService;
  binders: {
    id: string;
    fn: (time: number) => any
  }[];
  raf: number | null;
  now: number;
  time: number;
  deltaTime: number;

  constructor() {
    this.binders = [];
    this.raf = null;

    this.now = Date.now();
    this.time = this.now;
    this.deltaTime = 0;
  }

  /* Start */
  start() {
    if (!this.instance) this.instance = new RafService();
    this.instance.update();
  }

  /* Stop */
  stop() {
    window.cancelAnimationFrame(this.instance.raf);
  }

  /* Update */
  update() {
    // time stuff
    this.now = Date.now();
    this.deltaTime = this.now - this.time;
    this.time = this.now;

    for (let i = 0; i < this.binders.length; i++) this.binders[i].fn(this.deltaTime);

    this.raf = window.requestAnimationFrame(() => this.update());
  }

  /**
   * Bind
   * @param  {String}   id [description]
   * @param  {Function} fn [description]
   */
  bind(id, fn) {
    const _this = this.instance;

    if (typeof id !== 'string') {
      console.error('[raf.service] The first argument of the bind function must be a string.', id);
      return;
    }

    if (typeof fn !== 'function') {
      console.error('[raf.service] You must pass a valid function to the bind() function.', fn);
      return;
    }

    for (let i = 0; i < _this.binders.length; i++) {

      const b = _this.binders[i];

      if (b.id === id) {
        return;
      }
    }

    this.instance.binders.push({ id: id, fn: fn });
  }

  /**
   * Unbind
   * @param  {String}   id [description]
   */
  unbind(id) {
    const _this = this.instance;

    for (let i = 0; i < _this.binders.length; i++) {
      if (_this.binders[i].id === id) {
        _this.binders.splice(i, 1);
        break;
      }
    }
  }
}
