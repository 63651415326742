<form class="form" (ngSubmit)="submitForm(email.value)" novalidate>
  <div class="form__item">
      <div
        class="form__group"
        [ngClass]="{
          'form__group--error': (emailFormControl.dirty && emailFormControl.hasError('email')) || (emailFormControl.hasError('email') && !emailFormControl.hasError('required')),
          'form__group--success': !emailFormControl.hasError('email') && !emailFormControl.hasError('required')
        }"
      >
        <input class="form__control" [formControl]="emailFormControl" #email>
        <label class="form__item__label">{{'Common.Forms.email'|translate}}</label>
        <button
          class="form__item__status"
          type="submit"
          [disabled]="!emailFormControl.valid"
        ><span class="icon icon-arrow-md"></span>
        </button>

        <div
          class="form__item__msg"
          *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')"
        >
          {{'Common.Forms.Errors.email_error'|translate}}
        </div>
        <div
          class="form__item__msg"
          *ngIf="!emailFormControl.pristine && emailFormControl.hasError('required')"
        >
          {{'Common.Forms.Errors.email_required'|translate}}
        </div>
      </div>
    </div>
</form>
