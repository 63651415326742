import { Component, Input } from '@angular/core';
import { Tile } from '@app/models/content';
import {TileContext, TileFormat} from "@app/shared/enums";

@Component({
  selector: 'app-tile-game',
  templateUrl: './tile-game.component.html',
  styleUrls: ['./tile-game.component.scss']
})
export class TileGameComponent {

  @Input() tile: Tile;
  @Input() format: TileFormat;
  @Input() context: TileContext;

  constructor() { }

  ngOnInit() {}
}
