import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { User} from '@src/app/models/models';
import { ApiService } from '../api/api.service';
import { ToolsService } from '../tools/tools.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  usersRef: AngularFirestoreCollection<User>;

  constructor(
    private firestore: AngularFirestore,
    private cloudFunctions: AngularFireFunctions,
    private api: ApiService,
    private tools: ToolsService) { }

  createUser(user: User) {
    const firestoreUser = user;
    delete firestoreUser.password;
    firestoreUser.created = new Date();
    firestoreUser.updated = new Date();
    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('users').doc(user.id).set(firestoreUser)
      .then(res => resolve(res))
      .catch(err => reject(err));
    });
  }

  updateUser(user: User) {
    const firestoreUser = user;
    firestoreUser.updated = new Date();
    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('users').doc(user.id).set(firestoreUser)
      .then(res => resolve(res))
      .catch(err => reject(err));
    });
  }

  getUser(id: string) {
    return new Promise<User>((resolve, reject) => {
      this.firestore.collection('users').doc(id).get()
      .toPromise()
      .then(res => {
        if (res.exists) {
          resolve(res.data() as User);
        } else {
          reject('Document not found: ' + id);
        }
      })
      .catch(err => reject(err));
    });
  }

  deleteUser() {
    return this.cloudFunctions.httpsCallable('deleteAccount')(null);
  }
}
